import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as JfyLogo } from '../../assets/jfy-logo.svg';
import LoadingDots from '../../components/LoadingDots';
import checkoutBackend from '../../services/checkoutBackend';
import { sendPurchaseEvent } from '../../analytics/events';
import logger from '../../utils/logger';
import ReactGA from 'react-ga';
import styles from './PaymentProcessing.module.css';
import notify from '../../utils/notify';

export const PaymentProcessing = (args) => {
  const [countdown, setCountdown] = useState(15);
  const history = useHistory();

  let orderId = args.match.params.orderId;

  let checkPaymentStatus = () => {
    logger.debug('Requesting...');

    checkoutBackend('/payment/credit_card/status/' + orderId, 'GET', {}, true)
      .then((response) => {
        if (response.toLowerCase() === 'paid') {
          // Fetch order and then cart to send purchase event
          checkoutBackend('/orders/' + orderId, 'GET', {}, true)
            .then((orderResponse) => {
              checkoutBackend('/carts/' + orderResponse.cart_id, 'GET', {}, true)
                .then((cartResponse) => {
                  const urlSearch = new URLSearchParams(window.location.search);
                  sendPurchaseEvent(
                    orderId,
                    cartResponse.result,
                    'credit_card',
                    urlSearch.get('hairType'),
                    urlSearch.get('kit')
                  );
                })
                .catch((error) => {
                  logger.error(
                    'Failed to get cart info\n',
                    error,
                    '\n',
                    error.responseJson || null
                  );
                });
            })
            .catch((error) => {
              logger.error('Failed to get order info\n', error, '\n', error.responseJson || null);
            });

          // Redirect to final page
          history.push('/feedback/' + orderId + '/confirmed');
        } else if (response === 'INVALID_DATA') {
          checkoutBackend('/orders/' + orderId, 'GET', {}, true).then((orderFetchResponse) => {
            const cartToken = orderFetchResponse?.cart_id;
            const customer_id = orderFetchResponse?.customer_id;
            const address_id = orderFetchResponse?.address_id;
            history.push(`/checkout/payment/${cartToken}/${customer_id}/${address_id}`);
            notify('Dados de cartão inválidos', 'error');
          });
        }
      })
      .catch((error) => {
        logger.error('Failed to check payment status\n', error, '\n', error.responseJson || null);
      });

    return;
  };

  useEffect(() => {
    const unblock = history.block((_, action) => action === 'PUSH');

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        if (countdown % 3 === 0) {
          checkPaymentStatus();
        }
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      history.push('/feedback/' + orderId + '/processing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return (
    <div className={styles.paymentProcessing}>
      <div className={styles.header}>
        <JfyLogo className={styles.logo} alt="JustForYou Logo" />
      </div>

      <div className={styles.content}>
        <h1>Aguarde uns instantes</h1>
        <p>Estamos processando seu pedido! Não feche esta tela!</p>
        <LoadingDots />
      </div>
    </div>
  );
};

export default PaymentProcessing;
