export class NetworkResponseWasNotOk extends Error {
  constructor(message, responseJson) {
    super(message);
    this.name = 'Network response was not ok';
    this.responseJson = responseJson;
  }
}

export class CheckoutRequestError extends Error {
  constructor(method, url, realError) {
    super(`Failed to *${method}* \`${url}\` \nReal error: \`${realError}\``);
    this.name = 'CheckoutRequestError';
    this.realError = realError;
  }
}
