import { useState, useEffect } from 'react';
import qrGenerator from 'qr-image';

export const QrCode = ({ content = 'No content!', className }) => {
  const [qr, setQr] = useState({
    size: '0',
    path: '',
  });

  useEffect(() => {
    setQr(qrGenerator.svgObject(content, { type: 'svg' }));
  }, [content]);

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="current"
      viewBox={`0 0 ${qr.size} ${qr.size}`}
    >
      <path d={qr.path} />
    </svg>
  );
};

export default QrCode;
