import { CheckoutContainer, Block } from '../../components/CheckoutContainer';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp.svg';
import styles from './InvalidHash.module.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

export const InvalidHash = () => {

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <CheckoutContainer>
      <Block className={styles.block}>
        <div className={styles.wrap}>
          <h1>Seu código de compra é inválido</h1>
          <p>Caso seja a primeira vez que esteja usando, entre em contato com nosso time de atendimento</p>

          <div className={styles.contact}>
            <a
              href="https://bit.ly/jfy-client-whats"
              target="_blank"
              rel="noreferrer"
            >
              <div className={styles.icon}>
                <WhatsappIcon styles={{ height: '60px', width: '60px' }} />
              </div>
              <p>
                Mande mensagem pelo
                <br />
                <span>Whatsapp</span>
              </p>
            </a>
          </div>
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default InvalidHash;
