import checkoutBackend from '../../services/checkoutBackend';
import notify, { EMPTY_CART } from '../../utils/notify';
import formatPrice from '../../utils/formatPrice';
import logger from '../../utils/logger';

const voucherCodes = {
  notFound: 'NOT_FOUND',
  notActive: 'NOT_ACTIVE',
  notSet: 'NOT_SET',
  zipcodeLimit: 'ZIPCODE_NOT_COVERED',
  missingZipcode: 'MISSING_ZIPCODE',
  missingProductType: 'MISSING_PRODUCT_TYPE',
  insufficientProducts: 'PRODUCT_QUANTITY',
  minimunValue: 'MINIMUN_VALUE',
  voucherApplied: 'APPLIED',
  missingBenefit: 'MISSING_BENEFIT',
};

// UTILS

export const cartEmpty = (cart, toast = false) => {
  let counter = 0;
  cart.products.forEach((formula) => {
    formula.items.forEach((item) => {
      if (!item.benefit) counter += item.quantity;
    });
  });
  if (counter === 0) {
    if (toast) notify(EMPTY_CART, 'info');
    return true;
  } else {
    return false;
  }
};

export const changeProductsQuantity = (cart, quantity) => {
  cart.products.forEach((form) => {
    form.items.forEach((item) => {
      item.quantity += quantity;
    });
  });
  return cart;
};

export const formatProducts = (products) => {
  return products.map((form) => ({
    form_id: form.formId,
    form_name: form.formName,
    products: form.items.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
      weight: item.weight,
    })),
  }));
};

export const validateVoucher = async (cartToken, voucher, products, zipcode, shipment_cost) => {
  let voucherValidation = await validateCart(cartToken, products, voucher, zipcode, shipment_cost);
  return voucherValidation?.voucher;
};

export const validShippingValue = (cart, campaignCart, carrier) => {
  if (cart.voucher?.freeShipping || campaignCart) return { isFree: true, shipmentValue: 'Grátis' }
  if (cart.shipping?.fee === 0) return { isFree: true, shipmentValue: 'Grátis' }
  if (cart.voucher?.zip_limitation && cart.voucher?.shipment_value === 0) return { isFree: true, shipmentValue: 'Grátis' }
  if (cart.voucher?.zip_limitation && cart.voucher?.shipment_value > 0) return { isFree: false, shipmentValue: formatPrice(cart.voucher?.shipment_value) }

  if (carrier) return { isFree: false, shipmentValue: formatPrice(carrier.v_valfee) }
  return { isFree: false, shipmentValue: formatPrice(cart.shipping?.fee) }
}

export const validVoucher = (voucher) => {
  return voucher?.code === voucherCodes.voucherApplied && voucher?.is_valid;
};

export const getValidVoucherMessage = (cart, voucher) => {
  const percent = voucher?.is_percent || cart.voucher.percent;
  let discountValue = voucher?.discount_value || cart.voucher.discountValue;
  let message = ' ';
  if (discountValue) {
    if (percent) {
      message = `-${formatPrice(
        calculateDiscount(cart.totalValue, discountValue, percent)
      )} (${discountValue}% off)`;
    } else {
      message = `-${formatPrice(discountValue)}`;
    }
  }
  return message;
};

export const calculateDiscount = (totalValue, discount, percent) => {
  return percent ? (totalValue / 100) * discount : discount;
};

export const selectBestVoucher = (cart, vouchers) => {
  let bestVoucher = {};
  let bestFinalValue;
  let productsQuantity = 0;
  cart.products.forEach((formula) => {
    formula.items.forEach((item) => {
      productsQuantity += item.quantity;
    });
  });
  vouchers.forEach((voucher) => {
    if (voucher.minimun_spent > cart.totalValue) return;
    if (voucher.product_quantity > productsQuantity) return;

    let finalValue =
      cart.totalValue -
      calculateDiscount(cart.totalValue, voucher.discount_value, voucher.is_percent);

    if (cart.shipping && voucher.free_shipment) {
      finalValue -= cart.shipping.fee;
    }

    if (finalValue < bestFinalValue || !bestFinalValue) {
      bestVoucher = voucher;
      bestFinalValue = finalValue;
    }
  });

  return bestVoucher;
};

export const getFormulaEditURL = (cartToken, formId) => {
  let params = new URLSearchParams(window.location.search);
  if (formId) params.append('form', formId);
  return '/precheckout/' + cartToken + '/edit?' + params.toString();
};

export const checkBenefit = (cart, product) => {
  let productIsBenefit = false;
  if (product.benefit) productIsBenefit = true;
  return productIsBenefit;
};

export const addBenefitToCart = (cart, voucher) => {
  const benefit = {
    id: voucher.prd_id,
    sku: voucher.prd_sku,
    name: voucher.prd_name,
    displayName: voucher.prd_dspnam,
    description: voucher.prd_dscrp,
    value: 0,
    custom: voucher.prd_custom,
    brand: voucher.prd_brand,
    image: voucher.prd_image,
    shelf: voucher.prd_shelf,
    quantity: 1,
    benefit: true,
    size: voucher.prd_szdisp,
    weight: voucher.prd_weight,
  };
  if (benefit.id) {
    const index = cart.products.findIndex((form) => !form.formId);
    if (index !== -1) {
      if (!cart.products[index].items.find((item) => item.id === benefit.id)) {
        cart.products[index].items.push(benefit);
      }
    } else {
      cart.products.push({
        formId: null,
        formName: null,
        items: [benefit],
      });
    }
  }
  return cart;
};

export const removeBenefitFromCart = (cart) => {
  let emptyFormIndex = null;
  cart.products.forEach((form, index) => {
    form.items = form.items.filter((item) => {
      return !checkBenefit(cart, item);
    });
    if (form.items.length === 0) {
      emptyFormIndex = index;
    }
  });
  if (emptyFormIndex) {
    cart.products.splice(emptyFormIndex, 1);
  }
  return cart;
};

export const formatCartFinish = (cart) => {
  cart = removeBenefitFromCart(cart);
  let formattedCart = {
    products: formatProducts(cart.products),
    zipcode: cart.shipping.zipcode.replace('-', ''),
    carrier_id: cart.shipping.carrier,
    shipment_cost: cart.shipping.fee,
    total_value: cart.totalValue,
  };

  if (cart.voucher) {
    formattedCart.voucher_code = cart.voucher?.code;
    formattedCart.discount_value = calculateDiscount(
      cart.totalValue,
      cart.voucher.discountValue,
      cart.voucher.percent
    );
    const cartUseZipLimitationValue = (cart.voucher.freeShipping || (cart?.voucher?.zip_limitation && cart?.voucher?.shipment_value !== null));
    formattedCart.shipment_value = cartUseZipLimitationValue ? cart?.voucher?.shipment_value : cart.shipping.fee;
  } else {
    formattedCart.discount_value = 0;
    formattedCart.shipment_value = cart.shipping.fee;
  }
  return formattedCart;
};

export const cartSections = (cart) => {
  let sections = [];
  cart.products.forEach((form) => {
    let title = '';
    let actions = true;
    let quantityChanges = true;

    if (form.formId && form.formName) {
      title = 'Fórmula: ' + form.formName;
      actions = true;
      sections.push({
        title,
        actions,
        enableHeaderClass: true,
        quantityChanges,
        products: form,
      });
    } else {
      let genericProducts = [];
      let benefits = [];
      form.items.forEach((item) => {
        if (checkBenefit(cart, item)) {
          benefits.push(item);
        } else {
          genericProducts.push(item);
        }
      });
      if (genericProducts.length > 0) {
        sections.push({
          title: '',
          enableHeaderClass: false,
          actions: false,
          quantityChanges: true,
          products: { ...form, items: genericProducts },
        });
      }
      if (benefits.length > 0) {
        sections.push({
          title: 'Brindes',
          actions: false,
          enableHeaderClass: true,
          quantityChanges: false,
          products: { ...form, items: benefits },
        });
      }
    }
  });

  return sections;
};

// HANDLERS

export const getInvalidVoucherMessage = (voucher, availableCarriers) => {
  let message = '';
  let fix;
  if (voucher?.code === voucherCodes.insufficientProducts && voucher?.suggest_fix) {
    message = `Cupom aplicável a partir de ${voucher.expecting} produtos.`;
    fix = 'multipleForms';
  } else if (voucher?.code === voucherCodes.insufficientProducts && voucher?.expecting) {
    message = `Cupom aplicável a partir de ${voucher.expecting} produtos.`;
  } else if (voucher?.code === voucherCodes.minimunValue) {
    message = `Valor mínimo para aplicação não atingido`;
  } else if (!availableCarriers && voucher?.code === voucherCodes.missingZipcode) {
    message = `Insira um cep válido para proceguirmos`;
  } else if (availableCarriers && voucher?.code === voucherCodes.zipcodeLimit) {
    message = `Cupom indisponível para sua região`;
  } else {
    message = 'Cupom inválido';
  }

  return {
    message: message,
    fix: fix
  };
};

// REQUESTS

export const getVouchers = async () => {
  let vouchers = [];
  try {
    vouchers = await checkoutBackend('/vouchers?suggestion=true', 'GET', {}, true);
  } catch (error) {
    logger.error(error);
  }
  return vouchers;
};

export const getCarriers = async (zipcode, products) => {
  let carriers = [];
  zipcode = zipcode.replace('-', '');
  try {
    carriers = await checkoutBackend(
      `/logistics/carrier/zip/${zipcode}`,
      'PATCH',
      formatProducts(products),
      true
    );
  } catch (error) {
    logger.error(error);
  }
  return carriers;
};

export const validateCart = async (cartToken, products, voucher, zipcode, shipment_cost) => {
  let validation = {};
  try {
    const reqBody = {
      products: formatProducts(products),
    };
    if (voucher) reqBody.voucher_code = voucher;
    if (zipcode) reqBody.zipcode = zipcode.replace('-', '');
    if (shipment_cost) reqBody.shipment_cost = shipment_cost;
    validation = await checkoutBackend(`/carts/${cartToken}/validate`, 'PATCH', reqBody, true);
  } catch (error) {
    logger.error(error);
  }
  return validation;
};

export const deleteCartForm = async (cartToken, formId) => {
  let deleted = false;
  let url = '';
  if (formId) {
    url = `/carts/${cartToken}/form/${formId}`;
  } else {
    url = `/carts/${cartToken}/not_custom_products`;
  }
  try {
    await checkoutBackend(url, 'DELETE', {}, true);
    deleted = true;
  } catch (error) {
    logger.error(error);
  }
  return deleted;
};

export const finishCart = async (cart) => {
  let cartFinished = false;
  try {
    const response = await checkoutBackend(
      `/carts/${cart.token}/finish?track_event=true`,
      'PATCH',
      formatCartFinish(cart),
      true
    );
    if (!response?.detail) cartFinished = true;
  } catch (error) {
    logger.error(error);
  }

  return cartFinished;
};

export const validateDateDifference = (start_date, end_date) => {
  const start = new Date(start_date).getTime();
  const end = new Date(end_date).getTime();
  const today = new Date().getTime();

  return today <= end && today >= start;
};

export const filterValidVouchers = (vouchers) => {
  return vouchers.filter(({ start_date, end_date }) =>
    validateDateDifference(start_date, end_date)
  );
};

export const validateZipcode = async (zipcode) => {
  let validZipcode = false;
  zipcode = zipcode?.replace(/[^0-9]/g, '');

  try {
    const res = await checkoutBackend(`/payment/check_zipcode/${zipcode}`, 'GET', {}, true);
    validZipcode = res?.valid;
  } catch (error) {
    logger.silentError(error);
  }
  return validZipcode;
};


export const formatVoucherObject = (voucher) => {
  return {
    all_products: voucher.all_products,
    apply_once: voucher?.apply_once ? voucher?.apply_once : null,
    code: voucher?.code,
    description: voucher?.description,
    discount_value: voucher?.discount_value,
    end_date: voucher?.end_date,
    free_shipment: voucher?.free_shipment,
    id: voucher?.id,
    is_default_voucher: voucher?.is_default_voucher,
    is_percent: voucher?.is_percent,
    limit: voucher?.limit,
    minimun_spent: voucher?.minimun_spent,
    payment_method: voucher?.payment_method,
    product_quantity: voucher?.product_quantity,
    start_date: voucher?.start_date,
    zip_limitation: voucher?.zip_limitation ? voucher?.zip_limitation : null,
  }
}
