import { useHistory } from 'react-router-dom';
import { ProgressIndicator, Step } from '../ProgressIndicator';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as JfyLogo } from '../../assets/jfy-logo.svg';
import { ReactComponent as SecureBanner } from '../../assets/secure-label.svg';

import styles from './CheckoutContainer.module.css';

export const CheckoutContainer = ({
  children,
  step,
  className,
  onGoBack,
  cartToken,
  customerId,
  addressId,
  blocksClassName,
  ...props
}) => {
  const history = useHistory();

  const handleClick = (fromStep, goTo) => {
    if (fromStep < step) {
      history.push({
        pathname: goTo,
        search: history.location.search,
        state: { from: fromStep },
      });
    }
  };

  return (
    <div className={styles.checkoutContainer + (className ? ' ' + className : '')} {...props}>
      <div className={styles.header}>
        {onGoBack && (
          <div className={styles.iconGoBack} onClick={onGoBack}>
            <ArrowLeft />
          </div>
        )}
        <JfyLogo className={styles.logo} alt="JustForYou Logo" />
      </div>
      {step ? (
        <ProgressIndicator className={styles.progressIndicator}>
          <Step
            active={step === 1}
            onClick={(step) => handleClick(step, `/checkout/cart/${cartToken}`)}
            title="carrinho"
          />
          <Step
            active={step === 2}
            onClick={(step) => handleClick(step, `/checkout/personal-data/${cartToken}`)}
            title="dados"
          />
          <Step
            active={step === 3}
            onClick={(step) =>
              handleClick(step, `/checkout/shipping-info/${cartToken}/${customerId}`)
            }
            title="endereço"
          />
          <Step
            active={step === 4}
            onClick={(step) =>
              handleClick(step, `/checkout/payment/${cartToken}/${customerId}/${addressId}`)
            }
            title="pagamento"
          />
        </ProgressIndicator>
      ) : (
        <div className={styles.noProgressIndicator} />
      )}
      <div className={styles.blocksWrap}>
        <div className={styles.blocks + (blocksClassName ? ' ' + blocksClassName : '')}>
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.secure}>
          <SecureBanner alt="Compra Segura logo" />
        </div>
        <p className={styles.slogan}>JustForYou | Seu cabelo. Seu jeito. Sua fórmula.</p>
        <p className={styles.website}>justfor.com.br</p>
      </div>
    </div>
  );
};

export default CheckoutContainer;
export * from './Block';
