import { CheckoutContainer, Block } from '../../../components/CheckoutContainer';
import { ChoiceGroup, Choice } from '../../../components/ChoiceGroup';
import CreditCardPreview from '../../../components/CreditCardPreview';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import styles from './Payment.module.css';

import { ReactComponent as AmexSVG } from '../../../assets/payment/amex.svg';
import { ReactComponent as DinnersSVG } from '../../../assets/payment/dinners.svg';
import { ReactComponent as EloSVG } from '../../../assets/payment/elo.svg';
import { ReactComponent as HiperSVG } from '../../../assets/payment/hiper.svg';
import { ReactComponent as HipercardSVG } from '../../../assets/payment/hipercard.svg';
import { ReactComponent as MastercardSVG } from '../../../assets/payment/mastercard.svg';
import { ReactComponent as VisaSVG } from '../../../assets/payment/visa.svg';

export const Layout = ({
  cartInfos,
  handleShippmentFee,
  formatPrice,
  fields,
  setFieldValue,
  paymentMethods,
  hiddenPaymentMethods,
  cardPreviewData,
  handleChange,
  validateField,
  installmentsArray,
  handleGoToNextPage,
  formIsInvalid,
  processingPayment,
  cartToken,
  customerId,
  addressId,
  renderProducts,
  products,
  discountPaymentInfos,
  campaignOrder,
}) => {
  return (
    <CheckoutContainer
      step={4}
      cartToken={cartToken}
      customerId={customerId}
      addressId={addressId}
      blocksClassName={styles.blocks}
    >
      <Block
        title="Resumo do Pedido"
        collapsible={!campaignOrder}
        className={styles.rightBlock + (campaignOrder ? ` ${styles.campaign}` : '')}
      >
        <div className={styles.products}>{products?.length > 0 && renderProducts(products)}</div>
        <div className={styles.prices}>
          <div className={styles.discounts}>
            <div
              className={
                styles.price + (cartInfos?.voucher?.free_shipment ? ` ${styles.free}` : '')
              }
            >
              <h3>Frete</h3>
              <h3>{handleShippmentFee()}</h3>
            </div>
            {cartInfos?.cart?.cart_voucher ? (
              <div className={styles.price}>
                <h3>Cupom</h3>
                <h3>{formatPrice(cartInfos.discounts_value * -1)}</h3>
              </div>
            ) : null}
          </div>
          <div className={styles.total + ' ' + styles.price}>
            <h2>Total</h2>
            <h2 className={styles.green}>{formatPrice(cartInfos?.absolute_value)}</h2>
          </div>
        </div>
      </Block>
      <Block
        className={styles.leftBlock + (campaignOrder ? ` ${styles.campaign}` : '')}
        title={!campaignOrder ? 'Pagamento' : 'Confirmação'}
      >
        <div className={styles.paymentMethods}>
          {!campaignOrder ? (
            <h2>Escolha uma forma de pagamento</h2>
          ) : (
            <Button
              primary
              className={styles.button}
              onClick={() => handleGoToNextPage(false, true)}
              text="Finalizar Pedido"
              loading={processingPayment}
            />
          )}
          <ChoiceGroup
            selected={fields.paymentMethod?.value}
            onChange={(value) => setFieldValue('paymentMethod', value)}
            collapsible
            className={styles.choices}
          >
            {paymentMethods.credit_card?.active &&
              !hiddenPaymentMethods.current.includes('credit_card') && (
                <Choice
                  value="credit card"
                  title="Cartão de Crédito"
                  info={
                    discountPaymentInfos?.paymentType === 'credit_card' && (
                      <div className={styles.discount}>
                        <span>{discountPaymentInfos.discountValue}% de desconto!</span>
                      </div>
                    )
                  }
                >
                  <div className={styles.cardsContainer}>
                    <MastercardSVG height="25" alt="Mastercard" className={styles.cardIcon} />
                    <VisaSVG height="25" alt="Visa" className={styles.cardIcon} />
                    <AmexSVG height="25" alt="American Express" className={styles.cardIcon} />
                    <DinnersSVG height="25" alt="Dinners Club" className={styles.cardIcon} />
                    <EloSVG height="25" alt="Elo" className={styles.cardIcon} />
                    <HiperSVG height="25" alt="Hiper" className={styles.cardIcon} />
                    <HipercardSVG height="25" alt="Hipercard" className={styles.cardIcon} />
                  </div>
                  <div className={styles.cardPreview}>
                    <CreditCardPreview
                      number={cardPreviewData.number}
                      holder={cardPreviewData.holder}
                      expirationMonth={cardPreviewData.expirationMonth}
                      expirationYear={cardPreviewData.expirationYear}
                    />
                  </div>
                  <div className={styles.numberValidAndCvv}>
                    <div>
                      <h2 className={styles.label}>Número do cartão</h2>
                      <Input
                        placeholder="Digite o número escrito no cartão"
                        className={styles.input}
                        maxLength={16}
                        type="tel"
                        name="cardNumber"
                        autoComplete="cc-number"
                        onChange={handleChange}
                        value={fields.cardNumber?.value}
                        valid={!fields.cardNumber?.valid ? fields.cardNumber?.valid : null}
                        message={fields.cardNumber.message}
                        onBlur={validateField}
                        onlyDigit
                      />
                    </div>
                    <div>
                      <div className={styles.shortInputs}>
                        <div className={styles.expiration}>
                          <h2 className={styles.label}>Validade</h2>
                          <Input
                            className={styles.shortInput}
                            placeholder="MM/AA"
                            maxLength={4}
                            type="tel"
                            name="expirationDate"
                            autoComplete="cc-exp"
                            onChange={handleChange}
                            value={fields.expirationDate?.value}
                            valid={
                              !fields.expirationDate?.valid ? fields.expirationDate?.valid : null
                            }
                            onBlur={validateField}
                            message={fields.expirationDate.message}
                            onlyDigit
                          />
                        </div>
                        <div>
                          <h2 className={styles.label}>CVV</h2>
                          <Input
                            className={styles.shortInput}
                            placeholder="Código"
                            maxLength={4}
                            type="tel"
                            name="cvv2"
                            autoComplete="cc-csc"
                            onChange={handleChange}
                            value={fields.cvv2?.value}
                            valid={!fields.cvv2?.valid ? fields.cvv2?.valid : null}
                            message={fields.cvv2.message}
                            onlyDigit
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className={styles.label}>Nome completo </h2>
                  <Input
                    placeholder="Como está impresso no cartão"
                    className={styles.input}
                    maxLength={100}
                    type="text"
                    name="cardHolder"
                    autoComplete="cc-name"
                    onChange={handleChange}
                    onBlur={validateField}
                    value={fields.cardHolder?.value}
                    valid={!fields.cardHolder?.valid ? fields.cardHolder?.valid : null}
                    message={fields.cardHolder.message}
                  />
                  <div className={styles.mediumInputs}>
                    <div className={styles.document}>
                      <h2 className={styles.label}>CPF do titular</h2>
                      <Input
                        className={styles.input}
                        placeholder="000.000.000-00"
                        name="cpfCnpj"
                        type="tel"
                        maxLength={11}
                        onChange={handleChange}
                        onBlur={(e) => handleChange(e, true)}
                        value={fields.cpfCnpj?.value}
                        valid={!fields.cpfCnpj?.valid ? fields.cpfCnpj?.valid : null}
                        message={fields.cpfCnpj.message}
                        onlyDigit
                      />
                    </div>
                    <div>
                      <h2 className={styles.label}>Nascimento</h2>
                      <Input
                        className={styles.input}
                        placeholder="DD/MM/AAAA"
                        maxLength={8}
                        type="tel"
                        name="birthdate"
                        autoComplete="bday"
                        value={fields.birthdate?.value}
                        valid={!fields.birthdate?.valid ? fields.birthdate?.valid : null}
                        message={fields.birthdate.message}
                        onChange={handleChange}
                        onBlur={validateField}
                        onlyDigit
                      />
                    </div>
                  </div>
                  <h2 className={styles.label}>N° de Parcelas</h2>
                  <select name="installments" className={styles.select} onChange={handleChange}>
                    {installmentsArray.map((element, index) => (
                      <option value={element.installment_number} key={index}>
                        {element.installment_number +
                          'X de ' +
                          formatPrice(element.partial_value) +
                          ' sem juros'}
                      </option>
                    ))}
                  </select>
                  <Button
                    primary
                    className={styles.button}
                    onClick={() => handleGoToNextPage()}
                    text="Comprar"
                    disabled={formIsInvalid}
                    loading={processingPayment}
                  />
                </Choice>
              )}
            {paymentMethods.pix?.active && !hiddenPaymentMethods.current.includes('pix') && (
              <Choice
                value="pix"
                title="Pix"
                info={
                  discountPaymentInfos?.paymentType === 'pix' && (
                    <div className={styles.discount}>
                      <span>{discountPaymentInfos?.discountValue}% de desconto!</span>
                    </div>
                  )
                }
              >
                <p>
                  A confirmação de pagamento é realizada em poucos minutos. Utilize o aplicativo do
                  seu banco para pagar.
                </p>
                <h2>
                  <span className={styles.onlyDesktop}>Valor total no PIX: </span>
                  {discountPaymentInfos?.paymentType === 'pix' &&
                    discountPaymentInfos?.discountValue > 0
                    ? formatPrice(
                      cartInfos.absolute_value -
                      (cartInfos.absolute_value * discountPaymentInfos?.discountValue) / 100
                    )
                    : formatPrice(cartInfos.absolute_value)}
                </h2>
                <Button
                  primary
                  className={styles.button}
                  disabled={formIsInvalid}
                  onClick={() => handleGoToNextPage(true)}
                  text="Comprar"
                  loading={processingPayment}
                />
              </Choice>
            )}
          </ChoiceGroup>
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default Layout;
