const slackBot = {};

const SLACK_LOG = process.env.REACT_APP_SLACK_LOG?.toLowerCase?.() === 'true';

slackBot.sendMessage = (channel, type, ...messages) => {
  if (!SLACK_LOG || !process.env.REACT_APP_SLACK_WEBHOOK) return;
  let botUrl = '';
  if (channel === 'default') {
    botUrl = process.env.REACT_APP_SLACK_WEBHOOK;
  } else if (channel === 'third_services') {
    botUrl = process.env.REACT_APP_SLACK_THIRD;
  }

  let messagesFormated = '';
  messages[0] = messages[0]?.toString().replace('[JFY] ', '');

  messages.forEach((msg) => {
    if (msg === null || msg === undefined) return;

    if (msg.constructor === Object || msg.constructor === Array) {
      if (msg.credit_card) {
        msg.credit_card.credit_card_hash = '*confidential*';
      }

      msg = '```' + JSON.stringify(msg, null, 2) + '```';
    }
    messagesFormated += '\n' + msg;
  });

  fetch(botUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'plain_text',
            text: ':rotating_light: Checkout Front ' + type,
            emoji: true,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: messagesFormated,
          },
        },
      ],
    }),
  })
    .then(async (response) => {
      if (response.status !== 200) {
        console.error(
          `Error trying to send message to slack: ${response.status} - ${response.statusText
          }\n${await response.text()}`
        );
      }
    })
    .catch((error) => console.error(`Error trying to send message to slack\n${error}`));
};

export default slackBot;
