import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SessionContext } from '../../contexts/SessionContext';
import { getUrlParams } from '../../utils/urlParamsHandler';
import dataLayerHandler from '../../analytics/dataLayerHandler.js';
import {
  getProducts,
  formatCartUpdatePayload,
  formatCartContext,
  handleProductsChange,
  updateCart,
  nextPageSearchParams,
} from './productsHelper';
import Layout from './Layout';
import logger from '../../utils/logger';
import notify, { EMPTY_CART } from '../../utils/notify';

export const EditKit = () => {
  const sessionContext = useContext(SessionContext);
  const history = useHistory();
  const [shelfProducts, setShelfProducts] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [sessionForm, setSessionForm] = useState();

  const formatCart = (format, cartToken, products) => {
    if (format === 'payload') {
      return formatCartUpdatePayload(sessionContext.cart, products, sessionForm);
    } else if (format === 'context') {
      return formatCartContext(sessionContext.cart, products, sessionForm);
    }
  };

  const handleClickNext = async () => {
    setLoadingNextPage(true);
    if (cartProducts.length === 0) {
      notify(EMPTY_CART, 'info');
      setLoadingNextPage(false);
      return;
    }
    const cart = formatCart('payload', sessionContext.cart.token, cartProducts);
    const cartUpdated = await updateCart(cart);
    if (cartUpdated) {
      logger.trace('BuildKit: Cart updated');
      const updatedCart = sessionContext.updateCart(
        formatCart('context', sessionContext.cart.token, cartProducts)
      );
      const urlSearch = new URLSearchParams(window.location.search);
      const dataLayerCart = dataLayerHandler.formatCartForDataLayer(
        updatedCart,
        urlSearch.get('hairType'),
        urlSearch.get('kit')
      );
      dataLayerHandler.addToCart(dataLayerCart.products, dataLayerCart.cartToken);
      const cartSearchParams = nextPageSearchParams(urlSearch);
      history.push('/checkout/cart/' + sessionContext.cart.token + cartSearchParams);
    } else {
      setLoadingNextPage(false);
    }
  };

  const onQuantifierChange = (productId, quantity) => {
    const productChanged = shelfProducts.find((product) => product.prd_id === productId);
    setCartProducts(handleProductsChange(cartProducts, productChanged, quantity));
  };

  const getShelfProducts = async (URLForm) => {
    let products = await getProducts();
    let defaultCartProducts = [];
    products.forEach((product) => {
      if (URLForm?.formId) {
        if (!product.prd_custom) {
          product.prd_shelf = false;
        } else if (product.prd_shelf) {
          const sessionCartProduct = sessionContext.cart.products
            .find((form) => form.formId === URLForm.formId)
            ?.items.find((item) => item.id === product.prd_id);
          if (sessionCartProduct) {
            product.default = sessionCartProduct.quantity;
          } else {
            product.default = URLForm.items.length === 0 ? 1 : 0;
          }
          defaultCartProducts.push({ ...product, quantity: product.default });
        }
      } else {
        if (product.prd_custom) {
          product.prd_shelf = false;
        } else if (product.prd_shelf) {
          const sessionCartProduct = sessionContext.cart.products
            .find((form) => form.formId === null)
            ?.items.find((item) => item.id === product.prd_id);
          if (sessionCartProduct) {
            product.default = sessionCartProduct.quantity;
          } else {
            product.default = 0;
          }
          defaultCartProducts.push({ ...product, quantity: product.default });
        }
      }
    });
    if (defaultCartProducts.length === 0) {
      const cartSearchParams = nextPageSearchParams(new URLSearchParams(window.location.search));
      history.push(`/checkout/cart/${sessionContext.cart.token}${cartSearchParams}`);
    }
    setCartProducts(defaultCartProducts);
    setShelfProducts(products);
    setLoadingProducts(false);
  };

  useEffect(() => {
    const URLForm =
      getUrlParams(new URLSearchParams(document.location.search), 'form')?.form || null;
    const updatedSessionForm = sessionContext.cart.products.find((form) => form.formId === URLForm);
    setSessionForm(updatedSessionForm);
    getShelfProducts(updatedSessionForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      shelfProducts={shelfProducts}
      formulaName={sessionForm?.formName}
      loadingProducts={loadingProducts}
      onQuantifierChange={onQuantifierChange}
      handleClickNext={handleClickNext}
      loadingNextPage={loadingNextPage}
    />
  );
};

export default EditKit;
