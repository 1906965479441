import { cloneElement } from 'react';
import styles from './ProgressIndicator.module.css';

export const Step = ({ stepNumber, onClick, index, active = false, ...props }) => (
  <div className={index + ' ' + styles.step + (active ? ' ' + styles.active : '')} {...props}>
    <div className={styles.divider}></div>
    <div
      className={styles.box + (onClick && !active ? ' ' + styles.clickable : '')}
      onClick={onClick ? () => onClick(index) : null}
    >
      <p className={styles.number}>{stepNumber}</p>
    </div>
    <div className={styles.divider}></div>
  </div>
);

export const ProgressIndicator = ({ className, children }) => (
  <div className={styles.navigator + (className ? ' ' + className : '')}>
    <div className={styles.boxes}>
      {children.map((child, index) =>
        cloneElement(child, {
          stepNumber: index + 1,
          index: index,
          key: index
        })
      )}
    </div>

    <div className={styles.titles}>
      {children.map(({ props }, index) => (
        <h5
          key={index}
          className={
            styles.title +
            (props.active ? ' ' + styles.active : '') +
            (props.onClick && !props.active ? ' ' + styles.clickable : '')
          }
          onClick={() => props.onClick?.(index)}
        >
          {props.title}
        </h5>
      ))}
    </div>
  </div>
);

export default ProgressIndicator;
