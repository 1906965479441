import { ReactComponent as Icon } from '../../assets/icons/check.svg';
import styles from './Checkbox.module.css';

const Checkbox = ({ label, checked, ...props }) => {
  return (
    <>
      <label className={styles.checkbox}>
        <span className={styles.input}>
          <input type="checkbox" name="checkbox" checked={checked} {...props} />
          <span className={styles.control}>
            <Icon />
          </span>
        </span>
        <span className={styles.label}>{label}</span>
      </label>
    </>
  );
};

export default Checkbox;
