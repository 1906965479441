import LoadingDots from '../../components/LoadingDots';
import { ReactComponent as JfyLogo } from '../../assets/jfy-logo.svg';

import styles from './Loading.module.css';

export const Loading = () => {
  return (
    <div className={styles.loading}>
      <JfyLogo className={styles.logo} alt="JustForYou Logo" />
      <LoadingDots />
    </div>
  );
};
