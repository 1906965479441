const replacementPossibilities = [
  {
    endsWith: '.co',
    replaceThis: '.co',
    forThis: '.com',
  },
  {
    endsWith: '.con',
    replaceThis: '.con',
    forThis: '.com',
  },
  {
    endsWith: '.vom',
    replaceThis: '.vom',
    forThis: '.com',
  },
  {
    endsWith: '.coml',
    replaceThis: '.coml',
    forThis: '.com',
  },
  {
    endsWith: '.comm',
    replaceThis: '.comm',
    forThis: '.com',
  },
  {
    endsWith: '.coma',
    replaceThis: '.coma',
    forThis: '.com',
  },
  {
    endsWith: '.cim',
    replaceThis: '.cim',
    forThis: '.com',
  },
  {
    endsWith: '.com.com',
    replaceThis: '.com.com',
    forThis: '.com',
  },
  {
    endsWith: '@iclou.com',
    replaceThis: 'iclou.com',
    forThis: 'icloud.com',
  },
  {
    endsWith: '@gmail.com.br',
    replaceThis: 'gmail.com.br',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmai.com',
    replaceThis: 'gmai.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmaill.com',
    replaceThis: 'gmaill.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmal.com',
    replaceThis: 'gmal.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmeil.com',
    replaceThis: 'gmeil.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gma.com',
    replaceThis: 'gma.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gemail.com',
    replaceThis: 'gemail.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmil.com',
    replaceThis: 'gmil.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmeil.com',
    replaceThis: 'gmeil.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmaio.com',
    replaceThis: 'gmaio.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gmsil.com',
    replaceThis: 'gmsil.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gamil.com',
    replaceThis: 'gamil.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gnail.com',
    replaceThis: 'gnail.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@gamail.com',
    replaceThis: 'gamail.com',
    forThis: 'gmail.com',
  },
  {
    endsWith: '@hormail.com',
    replaceThis: 'hormail.com',
    forThis: 'hotmail.com',
  },
  {
    endsWith: '@hotmai.com',
    replaceThis: 'hotmai.com',
    forThis: 'hotmail.com',
  },
  {
    endsWith: '@hotmal.com',
    replaceThis: 'hotmal.com',
    forThis: 'hotmail.com',
  },
  {
    endsWith: '@hitmail.com',
    replaceThis: 'hitmail.com',
    forThis: 'hotmail.com',
  },
  {
    endsWith: '@hotmsil.com',
    replaceThis: 'hotmsil.com',
    forThis: 'hotmail.com',
  },
  {
    endsWith: '@hotnail.com',
    replaceThis: 'hotnail.com',
    forThis: 'hotmail.com',
  },
];

const getSuggestion = (type, value) => {
  if (value === undefined) return;

  if (type === 'email') {
    for (const possibility of replacementPossibilities) {
      if (value.endsWith(possibility.endsWith)) {
        return value.replace(possibility.replaceThis, possibility.forThis);
      }
    }
  }

  return;
};

export default getSuggestion;
