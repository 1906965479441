import LoadingDots from '../LoadingDots';

import styles from './Button.module.css';

const Button = ({
  text = 'Botão',
  primary = false,
  secondary = false,
  tertiary = false,
  loading = false,
  disabled = false,
  loadingDotsSize = '20px',
  icon,
  onClick,
  className,
  ...props
}) => {
  return (
    <button
      onClick={(disabled || loading) && onClick ? null : onClick}
      disabled={disabled}
      className={
        styles.btn +
        (primary ? ' ' + styles.primary : '') +
        (secondary ? ' ' + styles.secondary : '') +
        (tertiary ? ' ' + styles.tertiary : '') +
        (className ? ' ' + className : '')
      }
      {...props}
    >
      {icon && icon}
      <span>
        {loading ? (
          <LoadingDots
            className={styles.loading}
            dotsClassName={styles.dots}
            dotHeight={loadingDotsSize}
            dotWidth={loadingDotsSize}
          />
        ) : (
          text
        )}
      </span>
    </button>
  );
};

export default Button;
