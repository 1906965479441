import pagarme from 'pagarme';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dataLayerHandler from '../../analytics/dataLayerHandler';
import { SessionContext } from '../../contexts/SessionContext';
import useForm from '../../hooks/useForm';
import checkoutBackend from '../../services/checkoutBackend';
import {
  cpfCnpjMask,
  creditCardNumberMask,
  fullDateMask,
  shortDateMask,
} from '../../utils/formater';
import { formatPrice } from '../../utils/formatPrice';
import logger from '../../utils/logger';
import notify from '../../utils/notify';
import {
  birthdateSchema,
  cpfCnpjSchema,
  creditCardExpirationSchema,
  creditCardNumberSchema,
  holderNameSchema,
} from '../../utils/validator';
import Layout from './Layout';
import styles from './Layout/Payment.module.css';

const defaultCardPreviewData = {
  number: '1234567890123456',
  holder: 'Nome e Sobrenome',
  expirationMonth: '03',
  expirationYear: '22',
};

export const Payment = (args) => {
  const cartToken = args.computedMatch.params.token;
  const customerId = args.computedMatch.params.customerId;
  const addressId = args.computedMatch.params.addressId;
  const urlSearch = new URLSearchParams(window.location.search);
  const sessionContext = useContext(SessionContext);
  const [cartInfos, setCartInfos] = useState([]);
  const [products, setProducts] = useState([]);
  const [installmentsArray, setInstallmentsArray] = useState([]);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState({});
  const [discountPaymentInfos, setDiscountPaymentInfos] = useState({});
  const hiddenPaymentMethods = useRef([]);
  const urlUtms = useRef({});
  const history = useHistory();

  const {
    fields,
    handleChange,
    setFieldValue,
    handleFinish,
    formIsInvalid,
    validateField,
  } = useForm({
    cardNumber: {
      schema: creditCardNumberSchema,
      mask: creditCardNumberMask,
      required: true,
    },
    expirationDate: {
      schema: creditCardExpirationSchema,
      mask: shortDateMask,
      required: true,
    },
    cvv2: {
      required: true,
    },
    cardHolder: {
      required: true,
      schema: holderNameSchema,
    },
    cpfCnpj: {
      mask: cpfCnpjMask,
      schema: cpfCnpjSchema,
      required: true,
    },
    birthdate: {
      required: true,
      schema: birthdateSchema,
      mask: fullDateMask,
    },
    paymentMethod: {
      value: 'credit card',
    },
    installments: {
      value: '1',
      required: true,
    },
  });

  const [cardPreviewData, setCardPreviewData] = useState(defaultCardPreviewData);

  const sendDataLayerEvent = (paymentMethod) => {
    let dataLayerItems = dataLayerHandler.formatDataLayerCartItems(
      cartInfos,
      urlSearch.get('hairType'),
      urlSearch.get('kit')
    );

    dataLayerHandler.addPaymentInfo(
      dataLayerItems,
      cartInfos.absolute_value / 100,
      cartInfos?.voucher ? cartInfos?.voucher.code : '',
      paymentMethod,
      cartToken
    );
  };

  const handleGoToNextPage = async (pix = false, hash = false) => {
    setProcessingPayment(true);

    if (hash) {
      logger.trace('handleGoToNextPage', true, {}, { details: 'Hash payment' });
      let currentDate = new Date();
      let date =
        currentDate.getFullYear() +
        '-' +
        (currentDate.getMonth() + 1) +
        '-' +
        currentDate.getUTCDate();
      let reqBody = {
        token: cartToken,
        payment_type: 'hash',
        hash: sessionContext.campaignHash,
        customer_id: customerId,
        create_date: date,
        address_id: addressId,
        ...urlUtms.current,
      };
      let response = await checkoutBackend('/orders', 'POST', reqBody, false, true);
      if (response.status_code === 201) {
        history.push(
          `/feedback/${response.data.id_order}/confirmed/${urlSearch.toString ? '?' + urlSearch : ''
          }`
        );
      } else if (response.status_code === 400) {
        notify('Pedido não autorizado', 'error');
      } else {
        notify('Erro ao realizar pagamento, tente novamente em instantes', 'error');
      }
      setProcessingPayment(false);
    } else if (pix) {
      logger.trace('handleGoToNextPage', true, {}, { details: 'Pixel payment' });
      let currentDate = new Date();
      let date =
        currentDate.getFullYear() +
        '-' +
        (currentDate.getMonth() + 1) +
        '-' +
        currentDate.getUTCDate();
      let reqBody = {
        token: cartToken,
        payment_type: 'pix',
        customer_id: customerId,
        create_date: date,
        address_id: addressId,
        ...urlUtms.current,
      };
      checkoutBackend('/orders', 'POST', reqBody, true)
        .then((response) => {
          sendDataLayerEvent('PIX');
          history.push(
            `/checkout/awaiting-payment/${response.id_order}/${urlSearch.toString ? '?' + urlSearch : ''
            }`,
            {
              pixCode: response.qr_code,
            }
          );
        })
        .catch((error) => {
          setProcessingPayment(false);
          logger.error(
            'Failed to make a pix payment\n',
            error,
            '\n',
            error.responseJson || null,
            '\nBody of the Request:',
            reqBody
          );
        });
    } else {
      const { valid, details, data } = await handleFinish();
      logger.trace('handleGoToNextPage', valid, data, details);
      if (valid) {
        let currentDate = new Date();
        let date =
          currentDate.getFullYear() +
          '-' +
          (currentDate.getMonth() + 1) +
          '-' +
          currentDate.getUTCDate();

        let parseDate = data.expirationDate.value.split('/');

        let parsed_cpf = data.cpfCnpj.value.replace('-', '');
        parsed_cpf = parsed_cpf.replace(/\./g, '');

        let formattedInstallments =
          fields.installments.value +
          'x R$' +
          installmentsArray.find(
            (installment) => installment.installment_number === parseInt(fields.installments.value)
          ).partial_value /
          100;
        let expireDate = parseDate[0] + parseDate[1];

        let client = await pagarme.client.connect({
          encryption_key: process.env.REACT_APP_ENCRYPTION_PAGARME,
        });
        let card_hash = await client.security.encrypt({
          card_number: data.cardNumber.value,
          card_holder_name: data.cardHolder.value,
          card_expiration_date: expireDate,
          card_cvv: data.cvv2.value,
        });

        let reqBody = {
          token: cartToken,
          payment_type: 'credit_card',
          customer_id: customerId,
          create_date: date,
          address_id: addressId,
          credit_card: {
            credit_card_hash: card_hash,
            cvv: data.cvv2.value,
            exp_date: expireDate,
            customer_cpf: parsed_cpf,
            installment_count: fields.installments.value,
            installment_formatted: formattedInstallments,
            birth_date: data.birthdate.value,
            customer_fullname: data.cardHolder.value,
          },
          ...urlUtms.current,
        };

        let response = await checkoutBackend('/orders', 'POST', reqBody, false, true);
        let response_data = await response.data;
        let status_code = response.status_code;

        if (status_code === 201) {
          if (response_data.status.toLowerCase() === 'created') {
            sendDataLayerEvent('Credit Card');
            history.push(
              '/feedback/processing/' +
              response_data.id_order +
              `${urlSearch.toString ? '?' + urlSearch : ''}`
            );
          } else {
            logger.error(
              'Failed to make a credit card payment\n',
              'Response - status code:',
              status_code,
              'Response - data:',
              response_data,
              '\nBody of the Request:',
              reqBody
            );
          }
        } else if (status_code === 500 || status_code === 405) {
          let error = response_data;
          setProcessingPayment(false);
          logger.error(
            'Failed to make a credit card payment\n',
            error,
            '\n',
            error.responseJson || null,
            '\nBody of the Request:',
            reqBody
          );
        } else if (status_code === 502) {
          setProcessingPayment(false);
          notify(
            'Erro ao realizar pagamento com método utilizado, tente novamente em instantes',
            'error'
          );
          let error = response_data;
          logger.thirdParty(
            'Order processing timeout\n',
            error,
            '\n',
            error.responseJson || null,
            '\nCart token:',
            cartToken
          );
        } else {
          notify('Erro ao realizar pagamento com método utilizado', 'error');
          let error = response_data;
          logger.silentError(
            'Failed to make a credit card payment\n',
            error,
            '\n',
            error.responseJson || null,
            '\nStatus code of the Request:',
            status_code,
            '\nBody of the Request:',
            reqBody
          );
        }
      } else {
        setProcessingPayment(false);
        logger.trace(
          'Ops, você precisa responder todos os campos obrigatórios antes de prosseguir'
        );
      }
    }
  };

  const handleShippmentFee = () => {
    let shippmentFee;
    if (cartInfos && cartInfos.cart) {
      shippmentFee = cartInfos.cart.cart_shippment_fee;

      if (cartInfos.voucher && cartInfos.voucher.free_shipment) {
        return 'Grátis';
      }

      const zipLimitationValueIsFree = cartInfos?.voucher?.zip_limitation && Number(sessionContext.cart?.voucher?.shipment_value) === 0 && !!sessionContext.cart?.voucher?.shipment_value;
      if (zipLimitationValueIsFree) {
        return 'Grátis';
      }

      const zipLimitationValueIsValidToPrice = cartInfos?.voucher?.zip_limitation && Number(sessionContext.cart?.voucher?.shipment_value) > 0;
      if (zipLimitationValueIsValidToPrice) {
        return formatPrice(Number(sessionContext.cart?.voucher?.shipment_value));
      }
    }
    return formatPrice(shippmentFee);
  };

  const checkBenefit = (product) => {
    return product && sessionContext.cart.voucher?.benefits.includes(product.product_id);
  };

  const renderProducts = () => {
    products.sort((a, b) => b.products.length - a.products.length);
    if (products.length > 0) {
      let subtotal = 0;
      return (
        <>
          {products.map((element, index) => {
            return (
              <div key={index}>
                <div className={styles.kit}>
                  {element.formulaName && <h2>Fórmula: {element.formulaName}</h2>}
                  {element && element.products
                    ? element?.products.map((product, pIndex) => {
                      if (!checkBenefit(product)) {
                        subtotal += product.cart_product_value * product.quantity;
                        return (
                          <div key={pIndex} className={styles.item}>
                            <p className={styles.name}>
                              {product.quantity + 'x ' + product.product_display_name}
                            </p>
                            <p className={styles.price}>
                              {!sessionContext.campaignHash
                                ? formatPrice(product.cart_product_value)
                                : ''}
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div key={pIndex} className={styles.item}>
                            <p className={styles.name}>
                              {product.quantity + 'x ' + product.product_display_name}
                            </p>
                            <p className={styles.price}>Brinde</p>
                          </div>
                        );
                      }
                    })
                    : null}
                </div>
              </div>
            );
          })}
          {!sessionContext.campaignHash && (
            <div className={styles.subtotal}>
              <p>Subtotal:</p>
              <p className={styles.price}>{formatPrice(subtotal)}</p>
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const handleHiddenPayment = (urlSearch, utms) => {
    if (sessionContext.campaignHash) {
      hiddenPaymentMethods.current = ['pix', 'credit_card'];
    }

    // explicity URL params
    urlSearch.has('hidePM') &&
      urlSearch
        .get('hidePM')
        .split(',')
        .forEach((pm) => {
          hiddenPaymentMethods.current.push(pm);
        });

    if (urlSearch.get('hidePix')) {
      hiddenPaymentMethods.current.push('pix');
    }
    if (urlSearch.get('hideCard')) {
      hiddenPaymentMethods.current.push('credit_card');
    }

    setFieldValue(
      'paymentMethod',
      hiddenPaymentMethods.current.includes('credit_card') ? 'pix' : 'credit card'
    );
  };

  useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);

    urlUtms.current = {
      ...(urlSearch.has('utm_source') && { utm_source: urlSearch.get('utm_source') }),
      ...(urlSearch.has('utm_medium') && { utm_medium: urlSearch.get('utm_medium') }),
      ...(urlSearch.has('utm_campaign') && { utm_campaign: urlSearch.get('utm_campaign') }),
      ...(urlSearch.has('utm_content') && { utm_content: urlSearch.get('utm_content') }),
      ...(urlSearch.has('utm_term') && { utm_term: urlSearch.get('utm_term') }),
    };

    handleHiddenPayment(urlSearch, urlUtms.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fields.cardNumber?.value && !fields.cardHolder?.value && !fields.expirationDate?.value) {
      setCardPreviewData(defaultCardPreviewData);
    } else {
      setCardPreviewData({
        number: fields.cardNumber?.value || '-',
        holder: fields.cardHolder?.value || '-',
        expirationMonth: fields.expirationDate?.value?.substring?.(0, 2) || '-',
        expirationYear: fields.expirationDate?.value?.substring?.(3) || '-',
      });
    }
  }, [fields.cardHolder?.value, fields.cardNumber?.value, fields.expirationDate]);

  useEffect(() => {
    checkoutBackend('/discount_banner/active', 'GET', {}).then((response) => {
      if (response !== []) {
        const payment_discount_obj = {
          paymentType: response.payment_type,
          isPercent: response.is_percent,
          discountValue: response.discount_value,
          minimunValue: response.minimum_value,
        };
        setDiscountPaymentInfos(payment_discount_obj);
      }
    });

    checkoutBackend('/payment/methods', 'GET', {})
      .then((response) => {
        let availableMethods = {};
        response.forEach((method) => {
          availableMethods = {
            ...availableMethods,
            [method.pay_label]: {
              active: method.pay_active,
              discount: method.pay_discnt,
            },
          };
        });

        if (availableMethods.pix?.active || availableMethods.credit_card?.active) {
          setPaymentMethods(availableMethods);
        } else {
          logger.error(
            'No active payment method avaliable! The API did not return `pix` or `credit_card` \n',
            'Response:',
            response
          );
        }
      })
      .catch((error) => {
        setProcessingPayment(false);
        logger.error('Failed to make a pix payment\n', error, '\n', error.responseJson || null);
      });
  }, []);

  useEffect(() => {
    if (cartToken) {
      //GET cart infos
      checkoutBackend('/carts/' + cartToken, 'GET', {}).then(({ result: cart }) => {
        setCartInfos(cart);
        const parsedProducts = cart.products.map((element) => {
          return {
            formId: element.form_id,
            formulaName: element.form_name,
            products: element.items,
          };
        });
        setProducts(parsedProducts);
        let quantity = Number(process.env.REACT_APP_MAX_INSTALLMENTS) || 7;
        // cart.products.forEach((element) => {
        //   element.items.forEach((product) => {
        //     quantity = quantity + product.quantity;
        //   });
        // });
        let installments_number = [...Array(quantity).keys()];
        installments_number.shift();
        let total_value = cart.absolute_value;
        let creditCardDiscount = 0;
        if (discountPaymentInfos?.paymentType === 'credit_card') {
          creditCardDiscount = discountPaymentInfos.discountValue;
        }
        total_value -= (cart.absolute_value * creditCardDiscount) / 100;
        setInstallmentsArray(
          installments_number.map((element) => ({
            installment_number: element,
            partial_value: (total_value / element).toFixed(0),
          }))
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods.credit_card?.discount, cartToken]);

  return (
    <Layout
      cartInfos={cartInfos}
      handleShippmentFee={handleShippmentFee}
      formatPrice={formatPrice}
      fields={fields}
      setFieldValue={setFieldValue}
      paymentMethods={paymentMethods}
      hiddenPaymentMethods={hiddenPaymentMethods}
      cardPreviewData={cardPreviewData}
      handleChange={handleChange}
      validateField={validateField}
      installmentsArray={installmentsArray}
      handleGoToNextPage={handleGoToNextPage}
      formIsInvalid={formIsInvalid}
      processingPayment={processingPayment}
      cartToken={cartToken}
      customerId={customerId}
      addressId={addressId}
      renderProducts={renderProducts}
      discountPaymentInfos={discountPaymentInfos}
      products={products}
      campaignOrder={sessionContext.campaignHash}
    />
  );
};

export default Payment;
