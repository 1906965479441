import { useState, useRef, useEffect } from 'react';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg';

import styles from './Block.module.css';

export const Block = ({ className, children, title, collapsible, description }) => {
  const [blockOpen, setBlockOpen] = useState(false);
  const [height, setHeight] = useState('1000px');
  const contentSpace = useRef(null);

  const handleBlockCollapse = () => {
    if (collapsible) {
      setBlockOpen(!blockOpen);
      setHeight(blockOpen ? `${contentSpace.current.scrollHeight + 1}px` : '0px');
    }
  };

  useEffect(() => {
    setHeight(contentSpace.current.scrollHeight);
  }, []);

  return (
    <div
      className={
        styles.block +
        (className ? ' ' + className : '') +
        (collapsible ? ' ' + styles.collapsible : '')
      }
    >
      {(title || description) && (
        <div className={styles.title} onClick={handleBlockCollapse}>
          <div>
            {title && <h1>{title}</h1>}
            {collapsible && (
              <ArrowUp className={styles.upArrow + (blockOpen ? ' ' + styles.down : '')} />
            )}
          </div>
          {description && description}
        </div>
      )}
      <div
        className={styles.content}
        ref={contentSpace}
        style={{ maxHeight: (collapsible ? `${height}` : 'auto') }}
      >
        {children}
      </div>
    </div>
  );
};