// @flow
const { default: logger } = require('../utils/logger');
const { formatCartForDataLayer, formatDataLayerCartItems } = require('./utils');

// EVENTS

const viewProducts = (
  cartProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>
) => {
  const products = cartProducts.map((product) => ({
    id: product.item_id,
    name: product.item_name,
    price: product.price,
    quantity: product.quantity,
  }));

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_products',
    products: products,
  });
};

const addToCart = (
  cartProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  cartToken: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_to_cart',
    event_id: `add_to_cart-${cartToken}`,
    ecommerce: {
      items: cartProducts,
    },
  });
  viewProducts(cartProducts);
};

const removeFromCart = (
  cartProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  cartToken: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'remove_from_cart',
    event_id: `remove_from_cart-${cartToken}`,
    ecommerce: {
      items: cartProducts,
    },
  });
};

const beginCheckout = (
  checkoutProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  value: number,
  coupon: string,
  cartToken: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    event_id: `begin_checkout-${cartToken}`,
    ecommerce: {
      currency: 'BRL',
      value: value,
      coupon: coupon,
      items: checkoutProducts,
    },
  });
};

const addShippingInfo = (
  checkoutProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  value: number,
  coupon: string,
  shippingTier: string,
  cartToken: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_shipping_info',
    event_id: `add_shipping_info-${cartToken}`,
    ecommerce: {
      currency: 'BRL',
      value: value,
      coupon: coupon,
      shipping_tier: shippingTier,
      items: checkoutProducts,
    },
  });
};

const addPaymentInfo = (
  checkoutProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  value: number,
  coupon: string,
  paymentMethod: string,
  cartToken: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_payment_info',
    event_id: `add_payment_info-${cartToken}`,
    ecommerce: {
      currency: 'BRL',
      value: value,
      coupon: coupon,
      payment_type: paymentMethod,
      items: checkoutProducts,
    },
  });
};

const purchase = (
  purchaseProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  purchaseInfo: { transaction_id: string, value: number, shipping: number, coupon: string },
  cartToken: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    event_id: `purchase-${cartToken}`,
    ecommerce: {
      transaction_id: purchaseInfo.transaction_id,
      value: purchaseInfo.value,
      shipping: purchaseInfo.shipping,
      coupon: purchaseInfo.coupon,
      currency: 'BRL',
      items: purchaseProducts,
    },
  });
};

const purchaseUA = (
  purchaseProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  purchaseInfo: { transaction_id: string, value: number, shipping: number, coupon: string }
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase-UA',
    ecommerce: {
      currencyCode: 'BRL',
      purchase: {
        actionField: {
          id: purchaseInfo.transaction_id,
          revenue: purchaseInfo.value,
          shipping: purchaseInfo.shipping,
          coupon: purchaseInfo.coupon,
        },
        products: purchaseProducts.map((i) => {
          return {
            name: i.item_name,
            id: i.item_id,
            price: i.price,
            quantity: i.quantity,
          };
        }),
      },
    },
  });
};

// Universal Analytics transaction event
const transaction = (transactionInfo: {
  transaction_id: string,
  value: number,
  shipping: number,
  coupon: string,
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'transaction',
    transactionId: transactionInfo.transaction_id,
    transactionTotal: transactionInfo.value,
    transactionShipping: transactionInfo.shipping,
  });
};

const purchaseCustom = (
  paymentMethod: string,
  purchaseProducts: Array<{ item_id: string, item_name: string, price: number, quantity: number }>,
  purchaseInfo: { transaction_id: string, value: number, shipping: number, coupon: string },
  cartToken: string
) => {
  if (paymentMethod !== 'pix' && paymentMethod !== 'credit_card') {
    logger.silentError(`Wrong payment method alias ${paymentMethod} for custom purchase event`);
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: `purchase-${paymentMethod}`,
    event_id: `purchase_${paymentMethod}-${cartToken}`,
    ecommerce: {
      transaction_id: purchaseInfo.transaction_id,
      value: purchaseInfo.value,
      shipping: purchaseInfo.shipping,
      coupon: purchaseInfo.coupon,
      currency: 'BRL',
      items: purchaseProducts,
    },
  });
};

const userInfo = (email, phone) => {
  let phoneParsed = phone?.replace(/[^0-9]/g, "");
  if (phoneParsed) {
    phoneParsed = "+55" + phoneParsed;
  }

  const splitedEmail = email?.split("@");

  if (splitedEmail?.length > 0) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formUserData',
      fRqm_x: splitedEmail[0],
      fRqm_p: splitedEmail[1],
      k9aM24: phoneParsed || '',
    });
  }
}

module.exports = {
  addToCart,
  removeFromCart,
  beginCheckout,
  addPaymentInfo,
  addShippingInfo,
  purchase,
  purchaseUA,
  purchaseCustom,
  transaction,
  formatCartForDataLayer,
  formatDataLayerCartItems,
  userInfo
};
