import { useEffect, useState } from 'react';
import styles from './Quantifier.module.css';

const Quantifier = ({
  name,
  onChange,
  defaultValue,
  min = 0,
  max = 99,
  step = 1,
  active = true,
  ...props
}) => {
  const [qtd, setQtd] = useState(defaultValue || min);

  useEffect(() => {
    setQtd(defaultValue);
  }, [defaultValue]);

  const handleChange = (type) => {
    let qtdTemp = qtd;
    if (type === 'decrease') {
      if (qtd > min && active) {
        qtdTemp -= step;
      }
    } else {
      if (qtd < max && active) {
        qtdTemp += step;
      }
    }

    setQtd(qtdTemp);
    onChange?.(name, qtdTemp);
  };

  return (
    <div className={styles.quantifier}>
      <div
        className={styles.decrease + (active ? '' : ' ' + styles.disabled)}
        {...props}
        onClick={() => handleChange('decrease')}
      >
        -
      </div>
      <div className={styles.number}>{qtd}</div>
      <div
        className={styles.increase + (active ? '' : ' ' + styles.disabled)}
        {...props}
        onClick={() => handleChange('increase')}
      >
        +
      </div>
    </div>
  );
};

export default Quantifier;
