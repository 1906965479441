import * as yup from 'yup';
import * as msg from './messages';

yup.setLocale({
  mixed: {
    default: msg.FIELD_INVALID,
    required: msg.FIELD_REQUIRED,
    oneOf: msg.FIELD_ONE_OF,
    notOneOf: msg.FIELD_NOT_ONE_OF,
  },
  string: {
    length: msg.STRING_LENGTH,
    min: msg.STRING_MIN,
    max: msg.STRING_MAX,
    email: msg.STRING_EMAIL,
    url: msg.STRING_URL,
    trim: msg.STRING_TRIM,
    lowercase: msg.STRING_LOWERCASE,
    uppercase: msg.STRING_UPPERCASE,
    matches: msg.STRING_MATCHES,
  },
  number: {
    min: msg.NUMBER_MIN,
    max: msg.NUMBER_MAX,
    lessThan: msg.NUMBER_LESSTHAN,
    moreThan: msg.NUMBER_MORETHAN,
    notEqual: msg.NUMBER_NOTEQUAL,
    positive: msg.NUMBER_POSITIVE,
    negative: msg.NUMBER_NEGATIVE,
    integer: msg.NUMBER_INTEGER,
  },
  date: {
    min: msg.DATE_MIN,
    max: msg.DATE_MAX,
  },
  array: {
    min: msg.ARRAY_MIN,
    max: msg.ARRAY_MAX,
  },
});

export default yup;