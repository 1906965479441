import { useState, cloneElement, useEffect } from 'react';

export { Choice } from './Choice';

export const ChoiceGroup = ({ children, selected, collapsible, onChange, ...props }) => {
  const [choiceSelected, setChoiceSelected] = useState(selected);

  const handleChoiceChange = (choice) => {
    setChoiceSelected(choice);

    if (onChange) {
      onChange(choice);
    }
  };

  useEffect(() => {
    setChoiceSelected(selected);
  }, [selected]);

  return (
    <div {...props}>
      {children.map((child, index) => {
        if (child?.type?.displayName === 'Choice') {
          return cloneElement(child, {
            selected: choiceSelected,
            onChange: handleChoiceChange,
            variant: collapsible ? 'collapsible' : 'normal',
            key: index,
          });
        }

        return child;
      })}
    </div>
  );
};
