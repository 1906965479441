import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import checkoutBackend from '../../services/checkoutBackend.js';
import { SessionContext } from '../../contexts/SessionContext';
import logger from '../../utils/logger';
import { formatPrice } from '../../utils/formatPrice';
import { ReactComponent as JfyLogo } from '../../assets/jfy-logo.svg';
import { ReactComponent as ApprovedSVG } from '../../assets/approved.svg';
import { ReactComponent as DeniedSVG } from '../../assets/denied.svg';
import { ReactComponent as ProcessingSVG } from '../../assets/processing.svg';
import Button from '../../components/Button';
import ReactGA from 'react-ga';

import styles from './OrderFeedback.module.css';

const cloneCart = async (cart_token) => {
  return checkoutBackend(`/carts/${cart_token}/clone`, 'POST', { is_campaign: false }, true)
    .then((response) => response)
    .catch((error) => logger.error('Failed to clone cart\n', error));
};

export const OrderFeedback = (args) => {
  const history = useHistory();
  let orderId = args.match.params.orderId;
  let orderStatus = args.match.params.status;
  const sessionContext = useContext(SessionContext);
  const [orderInfos, setOrderInfos] = useState({});
  const [cartInfos, setCartInfos] = useState({});
  const [products, setProducts] = useState({});
  const [paymentMethod, setPaymentMethod] = useState();

  let feedbackContent = {
    confirmed: {
      id: 4,
      icon: () => <ApprovedSVG />,
      title: (paymentMethod) => `Compra via ${paymentMethod} aprovada!`,
      btnText: 'Ir para área do cliente',
      btnOnClick: () => {
        window.location.href = process.env.REACT_APP_CUSTOMER_AREA_URL;
      },
      showOrderInfo: true,
    },
    processing: {
      id: 3,
      icon: () => <ProcessingSVG />,
      title: () => 'Compra aguardando aprovação',
      btnText: 'Ir para área do cliente',
      btnOnClick: () => {
        window.location.href = process.env.REACT_APP_CUSTOMER_AREA_URL;
      },
      showOrderInfo: true,
    },
    denied: {
      id: 9,
      icon: () => <DeniedSVG />,
      title: () => 'Opa, deu um problema com seu cartão!',
      btnText: 'Fazer compra novamente',
      btnOnClick: async () => {
        const newToken = await cloneCart(cartInfos.cart.cart_id);
        if (newToken) {
          history.push({
            pathname: '/checkout/cart/' + newToken.car_id,
            search: history.location.search,
          });
        }
      },
      showOrderInfo: false,
      content: () => (
        <div className={styles.denied}>
          <p>Transação não autorizada</p>
          <h2>Como resolver</h2>
          <ul>
            <li>Você pode ter digitado algum dado errado, reveja</li>
            <li>Se ainda assim o erro permanecer, tente outro cartão ou forma de pagamento</li>
            <li>Não se preocupe, sua compra se mantém salva</li>
          </ul>
        </div>
      ),
    },
    expired: {
      id: 8,
      icon: () => <DeniedSVG />,
      title: () => 'Opa, seu PIX expirou! :(',
      btnText: 'Fazer compra novamente',
      btnOnClick: async () => {
        const newToken = await cloneCart(cartInfos.cart.cart_id);
        if (newToken) {
          history.push({
            pathname: '/checkout/cart/' + newToken.car_id,
            search: history.location.search,
          });
        }
      },
      showOrderInfo: false,
      content: () => (
        <div className={styles.expired}>
          <p>
            Sua compra não foi efetuada. Para garantir seus produtos faça uma nova compra, clicando
            no botão abaixo.
          </p>
        </div>
      ),
    },
  };

  const formatCarrierInfo = (cartInfos) => {
    if (cartInfos && cartInfos.cart) {
      if (cartInfos.cart.cart_shippment_fee === 0 || sessionContext.campaignHash || (cartInfos?.voucher?.zip_limitation && cartInfos?.voucher?.shipment_value === 0)) {
        return cartInfos.cart.carrier_name + ' - Grátis';
      } else if (cartInfos?.voucher?.zip_limitation && cartInfos?.voucher?.shipment_value > 0) {
        return cartInfos.cart.carrier_name + ' - ' + formatPrice(cartInfos?.voucher?.shipment_value);
      } else {
        return cartInfos.cart.carrier_name + ' - ' + formatPrice(cartInfos.cart.cart_shippment_fee);
      }
    }
    return null;
  };

  const checkBenefits = (products) => {
    let hasBenefits = false;
    if (!products.formulaName) {
      if (products.products.every((item) => item.benefit)) {
        hasBenefits = true;
      }
    }
    return hasBenefits;
  };

  useEffect(() => {
    const unblock = history.block((_, action) => action === 'PUSH');

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (orderId) {
      checkoutBackend('/orders/' + orderId, 'GET', {}, true)
        .then((response) => {
          logger.debug('order', response);
          // Credit card response from backend is 8, we need to handle it correctly in front
          // We can fix it using status 9, but we need to update all services too
          if (response.status_id === 8 && response.payment_type === 'credit_card') {
            Object.keys(feedbackContent).forEach((status) => {
              if (feedbackContent[status].id === 9) {
                history.push('/feedback/' + orderId + '/' + status);
              }
            });
          } else if (feedbackContent[orderStatus].id !== response.status_id) {
            Object.keys(feedbackContent).forEach((status) => {
              if (feedbackContent[status].id === response.status_id) {
                history.push('/feedback/' + orderId + '/' + status);
              }
            });
          }
          setPaymentMethod(response.payment_type !== 'pix' ? 'Cartão de Crédito' : 'Pix');
          setOrderInfos(response);
          checkoutBackend('/carts/' + response.cart_id, 'GET', {}, true)
            .then(async (_response) => {
              let cart = _response.result;
              logger.debug('cart', cart);

              const parsedProducts = cart.products.map((element) => ({
                formId: element.form_id,
                formulaName: element.form_name,
                products: element.items,
              }));
              parsedProducts.sort((a, b) => b.products.length - a.products.length);

              setProducts(parsedProducts);
              setCartInfos(cart);
            })
            .catch((error) => {
              logger.error('Failed to get cart info\n', error, '\n', error.responseJson || null);
            });
        })
        .catch((error) => {
          logger.error('Failed to get order info\n', error, '\n', error.responseJson || null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <div className={styles.orderFeedback}>
      <div className={styles.logo}>
        <JfyLogo className={styles.logo} />
      </div>
      <div className={styles.container}>
        <div className={styles.feedback + ' ' + styles[orderStatus]}>
          <h1>
            {!sessionContext.campaignHash
              ? feedbackContent[orderStatus].title(paymentMethod)
              : 'Pedido Confirmado'}
          </h1>
          {feedbackContent[orderStatus].icon()}
        </div>
        {feedbackContent[orderStatus].showOrderInfo ? (
          <>
            <div className={styles.orderInfo + ' ' + styles[orderStatus]}>
              <h3>N° do pedido: {orderId}</h3>
              {cartInfos?.cart && !sessionContext.campaignHash ? (
                <h3>Valor total: {formatPrice(cartInfos?.cart?.final_value)}</h3>
              ) : null}
              <h3 style={{ marginTop: '20px' }}>
                A partir daqui, acompanhe o status da sua compra pelo e-mail ou pela área do
                cliente!
              </h3>
            </div>
            <div className={styles.clientInfo}>
              <h1>Informações do pedido</h1>
              <h3>PRODUTOS ADQUIRIDOS</h3>
              {products.length > 0 &&
                products.map((form, i) => (
                  <div key={i}>
                    <p>
                      <b>
                        {checkBenefits(form)
                          ? 'Brindes'
                          : form.formulaName
                          ? `Fórmula: ${form?.formulaName}`
                          : ''}
                      </b>
                    </p>
                    {form.products.map((product, index) => (
                      <p key={index}>
                        {product.quantity} {product.product_display_name}
                      </p>
                    ))}
                  </div>
                ))}
              {orderInfos?.customer_address ? (
                <>
                  <h3>DADOS DE ENTREGA</h3>
                  <p>
                    <b>Nome do comprador:</b> {orderInfos ? orderInfos.customer_name : ''}
                  </p>
                  <p>
                    <b>Endereço:</b>{' '}
                    {orderInfos
                      ? orderInfos?.customer_address + ', ' + orderInfos?.customer_number
                      : ''}
                  </p>
                  <h3>MÉTODO DE ENVIO</h3>
                  <p>{cartInfos ? formatCarrierInfo(cartInfos) : ''} </p>
                  <h3>PREVISÃO DE ENTREGA</h3>
                  <p>Em {orderInfos.days_to_deliver} dias</p>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <>{feedbackContent[orderStatus].content()}</>
        )}
        <div className={styles.button}>
          <Button
            primary
            text={feedbackContent[orderStatus].btnText}
            onClick={feedbackContent[orderStatus].btnOnClick}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderFeedback;
