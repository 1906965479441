import checkoutBackend from '../../services/checkoutBackend';
import logger from '../../utils/logger';

export const validateZipcode = async (zipcode) => {
  let validZipcode = false;
  zipcode = zipcode?.replace(/[^0-9]/g, '');

  try {
    const res = await checkoutBackend(`/payment/check_zipcode/${zipcode}`, 'GET', {}, true);
    validZipcode = res?.valid;
  } catch (error) {
    logger.silentError(error);
  }
  return validZipcode;
};

export const fomartShippingInfoLimitCaracters = (info,limit) =>{
  //remove text between ( *** ) and limit caracters
  return info?.replace(/(\s\(.*?\))|<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?>|<\/\w+>/gi, '')?.substring(0,limit)
}
