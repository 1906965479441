import { useContext } from 'react';
import { SessionContext } from '../../contexts/SessionContext';
import { CheckoutContainer, Block } from '../../components/CheckoutContainer';
import Button from '../../components/Button';
import { ReactComponent as SquareBottlePlus } from '../../assets/icons/square-bottle-plus.svg';
import styles from './DeletedFormula.module.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

export const DeletedFormula = () => {
  const sessionContext = useContext(SessionContext);

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const redirectToForm = () => {
    let params = '';
    if (sessionContext?.cart?.token) {
      params += '?cartId=' + sessionContext.cart.token;
      if (sessionContext.cart?.voucher) {
        params += '&promocode=' + sessionContext.cart.voucher.code;
      }
    }
    const formUrl = `${process.env.REACT_APP_FORM_URL}/new${params}`;
    window.location.href = formUrl;
  };

  return (
    <CheckoutContainer>
      <Block className={styles.block}>
        <div className={styles.wrap}>
          <h1>Opa, você removeu a sua compra :(</h1>
          <p>Retorne ao form para fazer outra fórmula! </p>
          <SquareBottlePlus className={styles.img} />
          <Button
            primary
            onClick={redirectToForm}
            text="Retornar ao Form"
            className={styles.btnBackToForm}
          />
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default DeletedFormula;
