import { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import dataLayerHandler from '../../analytics/dataLayerHandler.js';
import { SessionContext } from '../../contexts/SessionContext';
import useForm from '../../hooks/useForm';
import { cepMask, noSpaceMask } from '../../utils/formater/masks';
import logger from '../../utils/logger';
import { getUrlParams } from '../../utils/urlParamsHandler';
import { cepSchema } from '../../utils/validator';
import {
  addBenefitToCart,
  cartEmpty,
  changeProductsQuantity,
  deleteCartForm,
  filterValidVouchers,
  finishCart,
  getCarriers,
  getFormulaEditURL,
  getInvalidVoucherMessage,
  getValidVoucherMessage,
  getVouchers,
  removeBenefitFromCart,
  validateZipcode,
  selectBestVoucher,
  validateVoucher,
  validVoucher,
  formatVoucherObject,
} from './cartHelper';

import Layout from './Layout';
import notify, { INVALID_ADDRESS } from '../../utils/notify';

export const Cart = (args) => {
  const sessionContext = useContext(SessionContext);
  const history = useHistory();
  const suggestionVouchers = useRef([]);
  const urlSearch = new URLSearchParams(window.location.search);
  const [carriers, setCarriers] = useState([]);
  const [voucherMessage, setVoucherMessage] = useState();
  const [loadingCarriers, setLoadingCarriers] = useState(false);
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [loadingChanges, setLoadingChanges] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [modal, setModal] = useState();
  const { fields, handleChange, validateField, handleFinish, setFieldValue } = useForm({
    zipcode: {
      value: '',
      required: true,
      mask: cepMask,
      schema: cepSchema,
    },
    voucher: {
      value: '',
      required: false,
      mask: noSpaceMask,
    },
    carrier: {
      value: '',
      required: true,
    },
  });

  const redirectToCorreiosPage = () => {
    window.open(process.env.REACT_APP_URL_PAGE_CORREIOS);
  };

  const redirectToMoreInfoPage = () => {
    window.location.href = process.env.REACT_APP_URL_PAGE_ORDERS_MORE_INFO;
  };

  const handleGoToNextPage = async () => {
    if (cartEmpty(sessionContext.cart, true)) {
      return;
    }
    setLoadingFinish(true);
    const { valid, details } = await handleFinish();
    logger.debug('Cart: Fields validation:', valid, fields, details);
    if (valid) {
      const cartFinished = await finishCart(sessionContext.cart);
      if (cartFinished) {
        logger.trace('Cart: Cart Finished');
        const dataLayerCart = dataLayerHandler.formatCartForDataLayer(
          sessionContext.cart,
          urlSearch.get('hairType'),
          urlSearch.get('kit')
        );
        dataLayerHandler.beginCheckout(
          dataLayerCart.products,
          dataLayerCart.value,
          dataLayerCart.coupon,
          dataLayerCart.cartToken
        );
        history.push(
          `/checkout/personal-data/${sessionContext.cart.token}` + window.location.search
        );
      }
    } else {
      logger.trace('Cart: Required fields not filled in');
    }
    setLoadingFinish(false);
  };

  const handleAddForm = () => {
    let formUrl = process.env.REACT_APP_FORM_URL + '/new?cartId=' + sessionContext.cart.token;
    if (sessionContext.cart.voucher) formUrl += '&promocode=' + sessionContext.cart.voucher.code;
    window.location.href = formUrl;
  };

  const handleFormulaEdit = (form) => {
    const dataLayerCart = dataLayerHandler.formatCartForDataLayer(
      sessionContext.cart,
      urlSearch.get('hairType'),
      urlSearch.get('kit')
    );
    dataLayerHandler.removeFromCart(dataLayerCart.products, dataLayerCart.cartToken);
    history.push(getFormulaEditURL(sessionContext.cart.token, form.formId));
  };

  const handleFormulaDelete = async (form) => {
    setModal({
      text: form.formName
        ? `Deseja remover a Fórmula ${form.formName}?`
        : 'Deseja remover produtos do carrinho?',
      formId: form.formId,
      cancelText: 'Cancelar',
      acceptText: 'Remover',
      onCancel: () => {
        setModal();
      },
      onAccept: async () => {
        setLoadingChanges(true);
        setModal();
        const deleted = await deleteCartForm(sessionContext.cart.token, form.formId);
        if (deleted) {
          logger.trace('Cart: Form deleted: ' + form.formId);
          const deletedForm = form.formId;
          let updatedCart = sessionContext.cart;
          const index = updatedCart.products.findIndex((form) => form.formId === deletedForm);
          if (index !== -1) {
            updatedCart.products.splice(index, 1);
            sessionContext.updateCart(updatedCart);
          }
          if (cartEmpty(updatedCart)) {
            history.push('/cart/empty');
          } else if (updatedCart.voucher?.code) {
            handleVoucher(updatedCart.voucher.code, false);
          }
        }
        setLoadingChanges(false);
      },
    });
  };

  const handleQuantifierChange = async (formId, productId, quantity) => {

    let updatedCart = sessionContext.cart;
    updatedCart.products
      .find((form) => form.formId === formId)
      .items.find((item) => item.id === productId).quantity = quantity;
    if (updatedCart.voucher || updatedCart.shipping) {
      setLoadingChanges(true);
      if (updatedCart.shipping) {
        await handleShippingFee();
      }
      if (updatedCart.voucher) {
        const suggestVoucher = !updatedCart.voucher.benefits?.length > 0;
        await handleVoucher(updatedCart.voucher.code, suggestVoucher);
      }
      setLoadingChanges(false);
    } else {
      sessionContext.updateCart(updatedCart);
    }
  };

  const applyVoucher = (voucher) => {
    let updatedCart = removeBenefitFromCart(sessionContext.cart);
    updatedCart = { ...updatedCart, voucher: voucher };
    updatedCart = addBenefitToCart(updatedCart, voucher);
    sessionContext.updateCart(updatedCart);
    setVoucherMessage(getValidVoucherMessage(updatedCart, voucher));
  };

  const handleVoucher = async (voucher = '', suggestVoucher = false) => {
    if (!sessionContext.cart.voucher || voucher) {
      if (cartEmpty(sessionContext.cart, true)) return;
      if (!voucher) voucher = fields.voucher.value;

      if (voucher) {
        setLoadingVoucher(true);
        const availableCarriers = carriers?.length > 0
        const voucherResult = await validateVoucher(
          sessionContext.cart.token,
          voucher,
          sessionContext.cart.products,
          fields.zipcode.value ? fields.zipcode.value : null,
          availableCarriers ? carriers[0].v_valfee : null
        );

        let seggestedVoucher;
        if (suggestVoucher) {
          seggestedVoucher = selectBestVoucher(sessionContext.cart, suggestionVouchers.current);
        }

        if (validVoucher(voucherResult)) {
          let bestVoucher = voucherResult.voucher;

          if (!suggestionVouchers.current.some(voucher => voucher.code === bestVoucher.code)) {
            suggestionVouchers.current = [...suggestionVouchers.current, formatVoucherObject(bestVoucher)];
          }

          if (suggestVoucher) {
            bestVoucher = selectBestVoucher(sessionContext.cart, [
              voucherResult.voucher,
              seggestedVoucher,
            ]);

            //if the best coupon is different from the one previously applied,
            //it is necessary to revalidate with the endpoint
            if (bestVoucher?.code !== voucherResult.voucher?.code && bestVoucher?.code) {
              handleVoucher(bestVoucher.code, suggestVoucher);
            }
          }
          applyVoucher(bestVoucher);
          logger.trace('Cart: Voucher applied');
        } else {
          logger.trace('Cart: Invalid voucher');
          const { message, fix } = getInvalidVoucherMessage(voucherResult, availableCarriers);
          if (fix === 'multipleForms') {
            setModal({
              text:
                'Parabéns! O seu cupom dá direito a uma nova fórmula! ' +
                'Você pode duplicar a que acabou de fazer ou' +
                'criar uma nova para presentear alguém muito especial. ' +
                'O que deseja fazer?',
              acceptText: 'Duplicar fórmula',
              onAccept: () => {
                sessionContext.updateCart(changeProductsQuantity(sessionContext.cart, 1));
                handleVoucher(voucherResult.voucher.code, false);
                setModal();
              },
              cancelText: 'Adicionar nova fórmula',
              onCancel: () => {
                window.location.href = process.env.REACT_APP_FORM_URL;
              },
            });
          } else if (suggestVoucher) {
            handleVoucher(seggestedVoucher.code, true);
          } else {
            removeBenefitFromCart(sessionContext.cart);
            sessionContext.updateCart({ ...sessionContext.cart, voucher: null });
          }

          if (message) setVoucherMessage(message);
        }

        setLoadingVoucher(false);
      }
    } else {
      logger.trace('Cart: Voucher removed');
      let updatedCart = removeBenefitFromCart(sessionContext.cart);
      sessionContext.updateCart({ ...updatedCart, voucher: null });
      setFieldValue('voucher', '');
      setVoucherMessage();
    }
  };

  const handleShippingFee = async (event) => {
    // Avoid running function twice when targeting button on input blur
    if (event?.type === 'blur' && event.relatedTarget?.id === 'shipping') return;

    if (!cartEmpty(sessionContext.cart, true) && !loadingCarriers) {
      setLoadingCarriers(true);
      const { data } = await handleFinish();
      if (data.zipcode.valid) {
        const carriers = await getCarriers(data.zipcode.value, sessionContext.cart.products);
        const zipcodeIsValid = await validateZipcode(data.zipcode.value);

        if (carriers.length > 0 && zipcodeIsValid) {
          if (carriers.length === 1) {
            setFieldValue('carrier', carriers[0].v_carrier_id);
            sessionContext.updateCart({
              ...sessionContext.cart,
              shipping: {
                zipcode: data.zipcode.value,
                fee: carriers[0].v_valfee,
                carrier: carriers[0].v_carrier_id,
              },
            });
            logger.trace('Cart: Carrier set');
          }
          setCarriers(carriers);
        } else {
          notify(INVALID_ADDRESS, 'error');
        }
      }
      setLoadingCarriers(false);
    }
  };

  const handleCarrierChange = (carrier) => {
    setFieldValue('carrier', carrier);
    sessionContext.updateCart({
      ...sessionContext.cart,
      shipping: {
        ...sessionContext.cart.shipping,
        carrier: carrier,
        fee: carriers.find((availableCarrier) => availableCarrier.v_carrier_id === carrier)
          ?.v_valfee,
      },
    });
    logger.trace('Cart: Carrier updated');
  };

  const handleZipcodeReset = () => {
    setFieldValue('zipcode', '');
    setFieldValue('carrier', '');
    setCarriers([]);

    let resetShipping = {
      ...sessionContext.cart,
      shipping: null,
    };

    const voucherShippingNeedsReset = resetShipping?.voucher?.code && resetShipping?.voucher?.zip_limitation && fields.voucher.value;
    if (voucherShippingNeedsReset) {
      resetShipping.voucher = { ...resetShipping?.voucher, shipment_value: null };
    };

    sessionContext.updateCart(resetShipping);
    logger.trace('Cart: Carrier removed');
  };

  const getSuggestionVouchers = async () => {
    const vouchersApi = await getVouchers();
    //helper
    const vouchers = filterValidVouchers(vouchersApi);
    suggestionVouchers.current = [...suggestionVouchers.current, ...vouchers];
  };

  useEffect(() => {
    getSuggestionVouchers();

    // Auto apply URL voucher
    const urlVoucher = getUrlParams(new URLSearchParams(window.location.search), ['promocode']);
    let existingVoucher;
    if (urlVoucher?.promocode) {
      existingVoucher = urlVoucher.promocode;
      setFieldValue('voucher', urlVoucher.promocode);
    } else if (sessionContext.cart.voucher) {
      existingVoucher = sessionContext.cart.voucher.code;
    }
    if (existingVoucher) {
      setFieldValue('voucher', existingVoucher);
      handleVoucher(existingVoucher, false)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const voucherValue = sessionContext?.cart?.voucher?.code || fields?.voucher?.value;

    if (carriers.length === 0) return;
    if (!sessionContext?.cart?.shipping) return;
    if (!voucherValue) return;

    handleVoucher(voucherValue, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers])

  return (
    <Layout
      cart={sessionContext.cart}
      fields={fields}
      invalidCart={false}
      handleChange={handleChange}
      validateField={validateField}
      loadingCarriers={loadingCarriers}
      loadingVoucher={loadingVoucher}
      loadingChanges={loadingChanges}
      loadingNextPage={loadingFinish}
      availableCarriers={carriers}
      voucherMessage={voucherMessage}
      onClickDeleteFormula={handleFormulaDelete}
      onClickEditFormula={handleFormulaEdit}
      onClickAddFormula={handleAddForm}
      onClickCalcShippingFee={handleShippingFee}
      onClickResetZipcode={handleZipcodeReset}
      onClickApplyPromocode={() => handleVoucher('', false)}
      onChangeQuantifier={handleQuantifierChange}
      onChangeCarrier={handleCarrierChange}
      handleGoToNextPage={redirectToMoreInfoPage}
      campaignCart={sessionContext.campaignHash}
      modal={modal}
      redirectToCorreiosPage={redirectToCorreiosPage}
    />
  );
};

export default Cart;
