import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cpfCnpjMask, cellphoneMask, fullDateMask } from '../../utils/formater';
import {
  cpfCnpjSchema,
  birthdateSchema,
  cellphoneSchema,
  emailSchema,
  nameSchema
} from '../../utils/validator';
import logger from '../../utils/logger';
import useForm from '../../hooks/useForm';
import dayjs from 'dayjs';
import { CheckoutContainer, Block } from '../../components/CheckoutContainer';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import checkoutBackend from '../../services/checkoutBackend';
import ReactGA from 'react-ga';

import styles from './PersonalData.module.css';
import { hash } from '../../utils/hash';

export const PersonalData = (args) => {
  const cartToken = args.computedMatch.params.token;
  const [existingCustomer, setExistingCustomer] = useState();
  const [optInChecked, setOptInChecked] = useState(true);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const history = useHistory();

  const { fields, setFieldValue, handleChange, validateField, handleFinish, formIsInvalid } =
    useForm({
      name: {
        required: true,
        schema: nameSchema,
      },
      email: {
        required: true,
        schema: emailSchema,
      },
      birthdate: {
        required: true,
        schema: birthdateSchema,
        mask: fullDateMask,
      },
      cpfCnpj: {
        required: true,
        mask: cpfCnpjMask,
        schema: cpfCnpjSchema,
      },
      phone: {
        required: true,
        mask: cellphoneMask,
        schema: cellphoneSchema,
      },
      optinWhatsapp: {
        value: true,
      },
    });

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleGoToNextPage = async () => {
    setLoadingNextPage(true);
    const { valid, details } = await handleFinish();
    logger.trace('handleGoToNextPage', valid, fields, details);
    let cartCustomerId;
    if (valid) {
      let cpf = fields.cpfCnpj.value;
      let cnpj = null;
      if (fields.cpfCnpj.value.length >= 14) {
        cnpj = fields.cpfCnpj.value;
      }
      let reqBody = {
        token: cartToken,
        name: fields.name.value,
        email: fields.email.value,
        phone: fields.phone.value,
        cpf: cpf,
        cnpj: cnpj,
        birth_date: dayjs(fields.birthdate.value, 'DD/MM/YYYY', true).format('YYYY-MM-DD'),
        allow_whatsapp: fields.optinWhatsapp.value === '' ? false : true,
      };

      checkoutBackend('/customers', 'POST', reqBody, true)
        .then((response) => {
          cartCustomerId = response;
          if (cartCustomerId) {
            window.sessionStorage.setItem('customerId', JSON.stringify(cartCustomerId));
            window.dataLayer.push({ hashedEmail: hash(fields.email.value) });

            history.push({
              pathname: `/checkout/shipping-info/${cartToken}/${cartCustomerId}`,
              search: history.location.search,
            });
          } else {
            logger.error('Failed to save user personal data\nNo cart customer id!');
          }
        })
        .catch((error) => {
          logger.error(
            'Failed to save user personal data\n',
            error,
            '\n',
            error.responseJson || null
          );
        });
    } else {
      setLoadingNextPage(false);
      logger.trace('Ops, você precisa responder todos os campos obrigatórios antes de prosseguir');
    }
  };

  useEffect(() => {
    const customerId = JSON.parse(window.sessionStorage.getItem('customerId')) || '';

    if (customerId && !existingCustomer) {
      checkoutBackend(`/customers/${customerId}`, 'GET', {}, true)
        .then((response) => {
          setFieldValue('name', response.name);
          setFieldValue('email', response.email);
          setFieldValue(
            'birthdate',
            dayjs(response.birthday_date, 'YYYY-MM-DD', true).format('DD/MM/YYYY')
          );
          setFieldValue('cpfCnpj', response.cpf || response.cnpj);
          setFieldValue('phone', response.ddd + response.phone);
          setFieldValue('optinWhatsapp', response.whatsapp_permission);
          setExistingCustomer(true);
        })
        .catch((error) => {
          logger.error(
            'Failed to get user personal data\n',
            error,
            '\n',
            error.responseJson || null
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCustomer]);

  return (
    <CheckoutContainer step={2} cartToken={cartToken}>
      <Block title="Dados Pessoais" className={styles.block}>
        <div className={styles.personalData}>
          <h2 className={styles.label}>Nome Completo</h2>
          <Input
            name="name"
            autoComplete="name"
            maxLength={120}
            onBlur={validateField}
            onChange={handleChange}
            value={fields.name?.value}
            valid={!fields.name?.valid ? fields.name?.valid : null}
            message={fields.name?.message}
            className={styles.input}
            placeholder="Seu nome"
            disabled={loadingNextPage}
          />
          <div className={styles.desktop2Columns}>
            <div>
              <h2 className={styles.label}>E-mail</h2>
              <Input
                name="email"
                type="email"
                autoComplete="email"
                maxLength={320}
                noSpace
                onChange={handleChange}
                onBlur={validateField}
                value={fields.email?.value}
                valid={!fields.email?.valid ? fields.email?.valid : null}
                message={fields.email?.message}
                placeholder="Seu e-mail"
                onSuggestion={(suggestion) => setFieldValue('email', suggestion, true)}
                disabled={loadingNextPage}
              />
            </div>
            <div>
              <h2 className={styles.label}>Data de nascimento</h2>
              <Input
                className={styles.shortInput}
                maxLength={8}
                type="tel"
                name="birthdate"
                autoComplete="bday"
                placeholder="DD/MM/AAAA"
                value={fields.birthdate?.value}
                valid={!fields.birthdate?.valid ? fields.birthdate?.valid : null}
                message={fields.birthdate?.message}
                onChange={handleChange}
                onBlur={validateField}
                onlyDigit
                disabled={loadingNextPage}
              />
            </div>
          </div>
          <div className={styles.desktop2Columns}>
            <div>
              <h2 className={styles.label}>CPF ou CNPJ</h2>
              <Input
                className={styles.shortInput}
                placeholder="000.000.000-00"
                name="cpfCnpj"
                type="tel"
                maxLength={14}
                onChange={handleChange}
                onBlur={validateField}
                value={fields.cpfCnpj?.value}
                valid={!fields.cpfCnpj?.valid ? fields.cpfCnpj?.valid : null}
                message={fields.cpfCnpj?.message}
                onlyDigit
                disabled={loadingNextPage}
              />
            </div>
            <div>
              <h2 className={styles.label}>Celular</h2>
              <div className={styles.phone}>
                <Input
                  className={styles.shortInput + ' ' + styles.phone}
                  placeholder="(00) 00000-0000"
                  type="tel"
                  name="phone"
                  autoComplete="tel"
                  maxLength={11}
                  onChange={handleChange}
                  onBlur={validateField}
                  value={fields.phone?.value}
                  valid={!fields.phone?.valid ? fields.phone?.valid : null}
                  message={fields.phone?.message}
                  prepend="+55"
                  onlyDigit
                  disabled={loadingNextPage}
                />
              </div>
              <div className={styles.checkbox}>
                <Checkbox
                  name="optinWhatsapp"
                  checked={optInChecked}
                  onClick={() => setOptInChecked(!optInChecked)}
                  label="Quero receber informações sobre a empresa e meus pedidos via WhatsApp"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <Button
            primary
            disabled={formIsInvalid}
            text="Ir para endereço"
            onClick={handleGoToNextPage}
            className={styles.button}
            loading={loadingNextPage}
          />
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default PersonalData;
