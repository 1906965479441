import { NetworkResponseWasNotOk, CheckoutRequestError } from '../utils/customErrors';

const checkoutBackend = async (endpoint = '', method, body = {}, checkResponseForError = false, returnStatusCode = false, sendAuthToken = false) => {
  let headers = {
    Token: process.env.REACT_APP_API_TOKEN,
    'auth-token': process.env.REACT_APP_API_AUTH_TOKEN,
  };

  let init = { method, headers };
  if (method !== 'GET') {
    init = { method, headers, body: JSON.stringify(body) };
  }

  return fetch(process.env.REACT_APP_API_BASE_URL + endpoint, init)
    .then(async (result) => {
      if (!result.ok && checkResponseForError) {
        if (result.status === 405) {
          window.location.reload();
        } else {
          throw new NetworkResponseWasNotOk(
            `${result.status} (${result.statusText})`,
            await result.json()
          );
        }
      }
      if (!returnStatusCode) {
        return result.json();
      } else {
        return {
          data: await result.json(),
          status_code: result.status
        };
      };
    })
    .catch((error) => {
      throw new CheckoutRequestError(
        init.method,
        process.env.REACT_APP_API_BASE_URL + endpoint,
        error
      );
    });
};

export default checkoutBackend;
