import { createContext, useContext } from 'react';
import { SessionContext } from './SessionContext';
import { getUrlParams, stringFromParamsObject } from '../utils/urlParamsHandler';
import checkoutBackend from '../services/checkoutBackend';
import formBackend from '../services/formBackend';
import dataLayerHandler from '../analytics/dataLayerHandler';
import logger from '../utils/logger';

const FlowContext = createContext({});

const FlowProvider = ({ children }) => {
  const sessionContext = useContext(SessionContext);

  const createCart = async (cart) => {
    try {
      await checkoutBackend('/carts', 'POST', cart, true);
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  };

  const updateCart = async (cart) => {
    try {
      await checkoutBackend('/carts/' + cart.token, 'PATCH', cart, true);
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  };

  const cloneCart = async (cartValidation, formId) => {
    let newToken;
    const campaignCart = cartValidation.is_campaign ? true : false;
    try {
      if ((campaignCart && formId) || cartValidation.reason === 'abandoned') {
        const { car_id } = await checkoutBackend(
          `/carts/${cartValidation.cart.cart_id}/clone`,
          'POST',
          { is_campaign: campaignCart, form_id: formId },
          true
        );
        newToken = car_id;
      }
    } catch (error) {
      logger.error(error);
    }
    return newToken;
  };

  const validateCart = async (cartToken) => {
    let cart;
    try {
      const response = await checkoutBackend(
        `/carts/${cartToken}/validate`,
        'GET',
        {},
        false,
        true
      );
      if (response.status_code === 200) {
        cart = await response.data;
      } else if (response.status_code === 404) {
        cart = (await response.data).detail;
      }
    } catch (error) {
      logger.error(error);
    }
    return cart;
  };

  const generateCartToken = async () => {
    let token;
    try {
      const { cart_token } = await checkoutBackend('/carts/checkout', 'POST', {}, true);
      token = cart_token;
    } catch (error) {
      logger.error(error);
    }
    return token;
  };

  const formatCart = (format, cartToken, products, utms, existingCart) => {
    let formatted = {};
    if (format === 'payload') {
      formatted.token = cartToken;
      formatted.products = products.map((form) => ({
        form_id: form.formId,
        products: form.items.map((item) => ({
          product_id: item.prd_id,
          quantity: item.quantity,
          custom: item.prd_custom,
        })),
      }));
      formatted.date = new Date().toISOString();
      formatted.utm_source = utms.utm_source;
      formatted.utm_medium = utms.utm_medium;
      formatted.utm_campaign = utms.utm_campaign;
      formatted.utm_content = utms.utm_content;
      formatted.utm_term = utms.utm_term;
    } else if (format === 'payloadUpdate') {
      formatted.token = cartToken;
      formatted.products = [];
      existingCart.products.forEach((cartForm) => {
        if (!products.some((form) => form.formId === cartForm.form_id)) {
          formatted.products.push({
            form_id: cartForm.form_id,
            products: cartForm.items.map((item) => ({
              product_id: item.id,
              quantity: item.quantity,
              custom: item.custom,
            })),
          });
        }
      });
      formatted.products = [
        ...formatted.products,
        ...products.map((form) => ({
          form_id: form.formId,
          products: form.items.map((item) => ({
            product_id: item.prd_id,
            quantity: item.quantity,
            custom: item.prd_custom,
          })),
        })),
      ];
    } else if (format === 'context') {
      if (!existingCart) {
        formatted.token = cartToken;
        formatted.products = products;
      } else {
        formatted.token = cartToken;
        formatted.products = [];
        existingCart.products.forEach((cartForm) => {
          if (!products.some((form) => form.formId === cartForm.form_id)) {
            formatted.products.push(cartForm);
          }
        });
        formatted.products = [...formatted.products, ...products];
        formatted.voucher = existingCart.voucher;
      }
    }

    return formatted;
  };

  const redirectToForm = () => {
    window.location.href = `${process.env.REACT_APP_FORM_URL}/new`;
  };

  const validateForm = async (formId) => {
    let form;
    await formBackend(formId, 'GET', {}, false, true)
      .then(async (result) => {
        if (result.status_code !== 422) {
          const formInfo = await result.data;
          if (formInfo._id) {
            form = formInfo;
            dataLayerHandler.userInfo(formInfo?.email, formInfo?.phoneNumber);
          }
        }
      })
      .catch((error) => {
        logger.error(error);
      });
    return form;
  };

  const handleUrlForm = async (formId) => {
    let validForm;
    if (formId) {
      validForm = await validateForm(formId);
    }
    if (!validForm) {
      redirectToForm();
    }
    return validForm;
  };

  const validateProducts = async (products, format) => {
    const reqBody = {
      products_list: products.map((product) => {
        return product.id;
      }),
      is_sku: format === 'sku',
    };
    let validProducts;
    try {
      const result = await checkoutBackend('/products/validate', 'POST', reqBody, true);
      validProducts = result.products;
      validProducts.forEach((validProduct) => {
        validProduct.quantity = products.find(
          (p) => p.id === (format === 'sku' ? validProduct.prd_sku : validProduct.prd_name)
        ).quantity;
      });
    } catch (error) {
      logger.error(error);
    }
    return validProducts;
  };

  const parseUrlProducts = (productsString = '') => {
    let parsedProducts = [];
    let format;
    if (!productsString) return { items: [] };
    const productsArray = productsString.split(',');
    productsArray.forEach((product) => {
      // First element will set the format used
      if (!format) {
        if (product.includes(':')) {
          format = 'sku';
        } else {
          format = 'name';
        }
      }
      if (product.includes(':') && format === 'sku') {
        const splitProduct = product.split(':');
        if (splitProduct.length > 0) {
          splitProduct[1] = parseInt(splitProduct[1]);
          if (isNaN(splitProduct[1])) splitProduct[1] = 1;

          const index = parsedProducts.findIndex((prod) => prod.id === splitProduct[0]);
          if (index === -1) {
            parsedProducts.push({
              id: splitProduct[0],
              quantity: splitProduct[1],
            });
          } else {
            parsedProducts[index].quantity += splitProduct[1];
          }
        }
      } else {
        const index = parsedProducts.findIndex((prod) => prod.id === product);
        if (index !== -1) {
          parsedProducts[index].quantity += 1;
        } else {
          parsedProducts.push({
            id: product,
            quantity: 1,
          });
        }
      }
    });
    return {
      format: format,
      items: parsedProducts,
    };
  };

  const handleAccess = async (cartToken, currentPath) => {
    let validUrlItems = [];
    const urlParams = getUrlParams(new URLSearchParams(document.location.search), [
      'products',
      'form',
      'promocode',
      'hidePM',
      'hidePix',
      'hideCard',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'gclid',
      'cpmhash',
      'hairType',
      'kit',
    ]);
    let hash = urlParams.cpmhash;
    let hashIsValid;
    if (urlParams.cpmhash) {
      hashIsValid = await checkoutBackend(
        '/hash/' + urlParams.cpmhash + '/validate',
        'GET',
        {},
        true,
        false,
        true
      );
      // checar na localstorage caso não tenha na url
      if (hashIsValid.valid) {
        sessionContext.updateCampaignHash(hash);
      } else {
        return '/cart/invalid';
      }
    }
    // delete urlParams.cpmhash;

    const utms = {
      source: urlParams.utm_source,
      medium: urlParams.utm_medium,
      campaign: urlParams.utm_campaign,
      content: urlParams.utm_content,
      term: urlParams.utm_term,
    };
    const parsedUrlProducts = parseUrlProducts(urlParams?.products);

    // Validate URL products
    let validProducts;
    let validForm;
    if (hashIsValid?.config?.products) {
      let products = hashIsValid?.config?.products;
      let parsedProducts = products.map((element) => {
        return {
          id: element.sku,
          quantity: element.quantity,
        };
      });
      if (sessionContext?.cart.token !== cartToken) {
        validProducts = await validateProducts(parsedProducts, 'sku');
      } else {
        validProducts = [];
      }
      const customProducts = validProducts.filter((product) => product.prd_custom);
      if (customProducts.length > 0) {
        validForm = await handleUrlForm(urlParams.form);
        validUrlItems.push({
          formId: validForm._id,
          formName: validForm.formulaName,
          items: customProducts,
        });
      }
      if (validProducts.length !== customProducts.length) {
        validUrlItems.push({
          formId: null,
          formName: null,
          items: validProducts.filter((product) => !product.prd_custom),
        });
      }
    } else if (parsedUrlProducts?.items.length > 0) {
      validProducts = await validateProducts(parsedUrlProducts.items, parsedUrlProducts.format);
      const customProducts = validProducts.filter((product) => product.prd_custom);
      if (customProducts.length > 0) {
        validForm = await handleUrlForm(urlParams.form);
        validUrlItems.push({
          formId: validForm._id,
          formName: validForm.formulaName,
          items: customProducts,
        });
      }
      if (validProducts.length !== customProducts.length) {
        validUrlItems.push({
          formId: null,
          formName: null,
          items: validProducts.filter((product) => !product.prd_custom),
        });
      }
    } else {
      if (urlParams.form) {
        validForm = await handleUrlForm(urlParams.form);
        validUrlItems.push({
          formId: validForm._id,
          formName: validForm.formulaName,
          items: [],
        });
      }
    }

    if (cartToken) {
      const validCart = await validateCart(cartToken);
      logger.trace('FlowContext: Valid cart:', validCart.valid);
      if (validCart.valid) {
        if (validCart.is_campaign) {
          const newCartToken = await cloneCart(validCart, urlParams.form);
          if (newCartToken) {
            logger.trace('FlowContext: Cart cloned:', newCartToken);
            return (
              '/checkout/cart/' +
              newCartToken +
              stringFromParamsObject(urlParams, [], ['form', 'products'])
            );
          } else {
            return '/';
          }
        } else if (currentPath.includes('/edit')) {
          if (validUrlItems.some((form) => form.items.length > 0)) {
            const cartUpdated = await updateCart(
              formatCart('payloadUpdate', cartToken, validUrlItems, utms, validCart.cart)
            );
            if (cartUpdated) {
              logger.trace('FlowContext: Cart updated');
              const updatedCart = sessionContext.updateCart(
                formatCart('context', cartToken, validUrlItems, utms, validCart.cart)
              );
              const dataLayerCart = dataLayerHandler.formatCartForDataLayer(
                updatedCart,
                urlParams.hairType,
                urlParams.kit
              );
              dataLayerHandler.addToCart(dataLayerCart.products, dataLayerCart.cartToken);
              return '/checkout/cart/' + cartToken;
            }
          } else {
            sessionContext.updateCart(
              formatCart('context', cartToken, validUrlItems, utms, validCart.cart)
            );
          }
        } else if (currentPath.includes('/precheckout/')) {
          logger.trace('FlowContext: Edit Redirect');
          return '/precheckout/' + cartToken + '/edit' + stringFromParamsObject(urlParams);
        } else {
          if (sessionContext.cart.token === cartToken) {
            validCart.cart.voucher = sessionContext.cart.voucher;
          }
          sessionContext.updateCart(validCart.cart);
        }
      } else {
        if (validCart.reason === 'NOT_FOUND') {
          logger.trace('FlowContext: Cart Not Found');
          sessionContext.updateCart(formatCart('context', cartToken, validUrlItems));
          if (!currentPath.includes('/precheckout/')) {
            return '/precheckout/' + cartToken + stringFromParamsObject(urlParams);
          }
        } else if (validCart.reason === 'abandoned' || validCart.is_campaign) {
          const newCartToken = await cloneCart(validCart, urlParams.form);
          if (newCartToken) {
            logger.trace('FlowContext: Cart cloned:', newCartToken);
            return '/checkout/cart/' + newCartToken + stringFromParamsObject(urlParams);
          } else {
            return '/';
          }
        } else {
          logger.trace('FlowContext: Finished Cart');
          return '/cart/finished' + stringFromParamsObject(urlParams);
        }
      }
    } else {
      cartToken = await generateCartToken();
      logger.trace('FlowContext: Cart Token created');
      if (validUrlItems.some((form) => form.items.length > 0)) {
        let cartCreationTries = 0;
        const maxTries = 3;

        while (cartCreationTries < maxTries) {
          let cartCreated = false;
          cartCreated = await createCart(formatCart('payload', cartToken, validUrlItems, utms));
          if (cartCreated) {
            logger.trace('FlowContext: Cart created');
            const updatedCart = sessionContext.updateCart(
              formatCart('context', cartToken, validUrlItems)
            );
            const dataLayerCart = dataLayerHandler.formatCartForDataLayer(
              updatedCart,
              urlParams.hairType,
              urlParams.kit
            );
            dataLayerHandler.addToCart(dataLayerCart.products, dataLayerCart.cartToken);
            return (
              '/checkout/cart/' +
              cartToken +
              stringFromParamsObject(urlParams, [], ['form', 'products'])
            );
          } else {
            logger.trace('FlowContext: Cart creation failed');
            cartCreationTries++;
            cartToken = await generateCartToken();
            logger.trace('FlowContext: New Cart Token created');
            if (cartCreationTries === maxTries) {
              logger.error('Backed returned error in cart creation');
              throw new Error('Backed returned error in cart creation');
            }
          }
        }
      } else {
        sessionContext.updateCart(formatCart('context', cartToken, validUrlItems));
        logger.trace('FlowContext: BuildKit Redirect');
        return '/precheckout/' + cartToken + stringFromParamsObject(urlParams);
      }
    }
  };

  return <FlowContext.Provider value={{ handleAccess }}>{children}</FlowContext.Provider>;
};

export { FlowContext, FlowProvider };
