import styles from './Select.module.css';

export function Select({
  value,
  name,
  className,
  classNameContainer,
  onChange,
  data,
  valid,
  message,
  onBlur,
  ...props
}) {
  const handleChange = (event) => {
    if (onChange) {
      onChange?.(event);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur?.(event);
    }
  };

  return (
    <div className={classNameContainer}>
      <div
        className={
          styles.container +
          (valid ? ' ' + styles.valid : '') +
          (valid === false ? ' ' + styles.invalid : '')
        }
      >
        <select
          name={name}
          value={value}
          className={className}
          onChange={handleChange}
          onBlur={handleBlur}
          {...props}
        >
          {data.map((element, index) => (
            <option value={element.value} key={index}>
              {element.label}
            </option>
          ))}
        </select>
      </div>
      {valid !== undefined && message && (
        <p
          className={
            styles.message +
            ' ' +
            (valid ? ' ' + styles.valid : '') +
            (valid === false ? ' ' + styles.invalid : '')
          }
        >
          {message}
        </p>
      )}
    </div>
  );
}
