import { hotjar } from 'react-hotjar';
import googleTagManager from 'react-gtm-module';
import ReactGA from 'react-ga';


/**
 * Only track in production and if user let it.
 */
export const startTracking = () => {
  sessionStorage.setItem('trackingEnabled', isTrackingEnabled().toString());
  initTracking();
};

export const isTrackingEnabled = () => {
  if (typeof window === 'undefined') return true;

  if (process.env.NODE_ENV !== 'production') return false;

  const external = window?.external;
  const msTracking =
    typeof external !== 'undefined' &&
    'msTrackingProtectionEnabled' in external &&
    typeof external.msTrackingProtectionEnabled === 'function' &&
    window.external.msTrackingProtectionEnabled();

  const navigator = window?.navigator;

  const dnt = window?.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || msTracking;

  if (!dnt) return true;

  if (dnt === true || dnt === 1 || dnt === 'yes' || (typeof dnt === 'string' && dnt.charAt(0) === '1')) {
    console.log('[Tracking] Do not track found, trackers disabled.');
    return false;
  }

  return true;
};

export const initTracking = () => {
  if (sessionStorage.getItem('trackingEnabled') === 'false') return;

  ReactGA.initialize('UA-125502498-1');
  googleTagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
};
