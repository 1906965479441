import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FlowContext } from '../contexts/FlowContext';
import { Loading } from '../pages/Loading';

const CheckoutRoute = ({ component: Component, ...rest }) => {
  const flowContext = useContext(FlowContext);
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const history = useHistory();

  const accessHandler = async () => {
    const cartToken = rest.computedMatch.params.token || null;
    const route = await flowContext.handleAccess(cartToken, window.location.pathname);
    if (route) {
      setRedirecting(Boolean(route));
      history.push(route);
    } else {
      setRedirecting(false);
    }
    setLoading(false);
  };

  const loadComponent = (Component, loading, redirecting) => {
    return Component && !loading && !redirecting;
  };

  useEffect(() => {
    setLoading(true);
    setRedirecting(false);
    accessHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Component, window.location.pathname]);

  return loadComponent(Component, loading, redirecting) ? <Component {...rest} /> : <Loading />;
};

export default CheckoutRoute;
