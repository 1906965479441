import { CheckoutContainer, Block } from '../../../components/CheckoutContainer';
import { Cart } from '../../../models/models';
import Quantifier from '../../../components/Quantifier';
import { ChoiceGroup, Choice } from '../../../components/ChoiceGroup';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Dropdown from '../../../components/Dropdown';
import Modal from '../../../components/Modal';
import formatPrice from '../../../utils/formatPrice';
import { calculateDiscount, cartSections, validShippingValue } from '../cartHelper';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as PensilIcon } from '../../../assets/icons/pensil.svg';
import { ReactComponent as BottlePlusIcon } from '../../../assets/icons/bottle-plus.svg';
import { ReactComponent as TruckIcon } from '../../../assets/icons/truck.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/infoIcon.svg';

import styles from './Cart.module.css';

export const Layout = ({
  cart = new Cart(),
  fields,
  invalidCart,
  handleChange,
  loadingCarriers,
  loadingVoucher,
  loadingChanges,
  loadingNextPage,
  availableCarriers,
  voucherMessage,
  modal,
  onClickDeleteFormula,
  onClickEditFormula,
  onClickAddFormula,
  onClickCalcShippingFee,
  onClickResetZipcode,
  onClickApplyPromocode,
  onChangeCarrier,
  onChangeQuantifier,
  handleGoToNextPage,
  campaignCart,
  redirectToCorreiosPage,
}) => {
  const anyLoading = () => {
    const loading = loadingCarriers || loadingVoucher || loadingChanges || loadingNextPage;
    return loading;
  };

  const activeQuantifiers = () => {
    return !anyLoading || !campaignCart;
  };

  return (
    <>
      <CheckoutContainer
        step={1}
        className={styles.cart + (campaignCart ? ` ${styles.campaign}` : '')}
        cartToken={cart.token}
      >
        <Block className={styles.productsSummary} title="Seu carrinho">
          <div className={styles.formulas}>
            <>
              {cartSections(cart).map((section, index) => {
                return (
                  <div className={styles.formula} key={index}>
                    <div className={section.enableHeaderClass ? styles.header : undefined}>
                      <div className={styles.info}>
                        <h2 className={styles.name}>{section.title}</h2>
                      </div>
                      {section.actions && (
                        <div className={styles.actions}>
                          <div
                            onClick={() => !anyLoading() && onClickDeleteFormula(section.products)}
                          >
                            <TrashIcon className={styles.trashIcon} />
                          </div>
                          <div
                            onClick={() => !anyLoading() && onClickEditFormula(section.products)}
                          >
                            <PensilIcon className={styles.pensilIcon} />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.products + ' ' + styles.border}>
                      {section.products.items.map((product, index) => {
                        return (
                          <div className={styles.product} key={index}>
                            <div className={styles.wrapper}>
                              <div className={styles.imageWrap}>
                                <img src={product.image} alt={product.displayName} />
                              </div>
                              <div className={styles.details}>
                                <h3 className={styles.name}>{product.displayName}</h3>
                                <p className={styles.description}>{product.size}</p>
                                {section.quantityChanges ? (
                                  <h3 className={styles.price}>{formatPrice(product.value)}</h3>
                                ) : null}
                              </div>
                              {section.quantityChanges ? (
                                <Quantifier
                                  active={activeQuantifiers()}
                                  name={product.id}
                                  min={0}
                                  max={15}
                                  defaultValue={product.quantity}
                                  onChange={(id, value) =>
                                    onChangeQuantifier(section.products.formId, id, value)
                                  }
                                />
                              ) : (
                                <h2 className={styles.benefit}>x{product.quantity}</h2>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          <div className={styles.subtotalWrap}>
            <div className={styles.subtotal}>
              <p>Subtotal:</p>
              <p className={styles.price}>{formatPrice(cart.totalValue)}</p>
            </div>
          </div>
        </Block>
        <div className={styles.rightBlocks}>
          <Block className={styles.addMoreFormulas}>
            <div className={styles.wrap}>
              <h4>Adicione mais fórmulas à sua compra!</h4>
              <div className={styles.content}>
                <BottlePlusIcon className={styles.bottlePlus} />
                <div className={styles.callToAction}>
                  <p>
                    Você pode fazer fórmulas para outras pessoas e finalizar todos os pedidos em uma
                    só compra!
                  </p>
                  <Button
                    secondary
                    text="Quero fazer mais fórmulas"
                    className={styles.btnAddFormula}
                    onClick={onClickAddFormula}
                    disabled={loadingNextPage}
                  />
                </div>
              </div>
            </div>
          </Block>
          <Block className={styles.prices}>
            <div className={styles.shipping}>
              <div className={styles.title}>
                <TruckIcon className={styles.truckIcon} />
                <h4>
                  Meios de envio
                  <InfoIcon className={styles.infoZipcode} onClick={redirectToCorreiosPage} />
                </h4>
              </div>
              {availableCarriers.length > 0 ? (
                <>
                  <div className={styles.change}>
                    <p>
                      Entrega para o CEP: <b>{cart.shipping?.zipcode}</b>
                    </p>
                    <Button tertiary text="Alterar CEP" onClick={onClickResetZipcode} />
                  </div>
                  <h2>Escolha uma forma de entrega</h2>
                  <ChoiceGroup
                    selected={cart.shipping.carrier}
                    onChange={(carrier) => onChangeCarrier(carrier)}
                  >
                    {availableCarriers.map((carrier, index) => (
                      <Choice
                        title={carrier.v_carrier_alias}
                        value={carrier.v_carrier_id}
                        description={`Entregue em até ${carrier.v_expday} dias úteis`}
                        info={validShippingValue(cart, campaignCart, carrier).shipmentValue}
                        infoClassName={validShippingValue(cart, campaignCart, carrier).isFree ? 'color-green-8' : ''}
                        key={index}
                      ></Choice>
                    ))}
                  </ChoiceGroup>
                </>
              ) : (
                <div className={styles.calculate}>
                  <Input
                    type="tel"
                    name="zipcode"
                    onlyDigit
                    maxLength={8}
                    className={styles.cep}
                    placeholder="Seu CEP"
                    autoComplete="shipping postal-code"
                    value={fields.zipcode?.value}
                    valid={!fields.zipcode?.valid ? fields.zipcode?.valid : null}
                    message={fields.zipcode?.message}
                    onChange={handleChange}
                    onBlur={onClickCalcShippingFee}
                  />
                  <div className={styles.btnWrap}>
                    <Button
                      className={styles.btn}
                      secondary
                      name="zipcode"
                      id="shipping"
                      text="Calcular"
                      onClick={onClickCalcShippingFee}
                      loading={loadingCarriers}
                      disabled={loadingVoucher || loadingChanges || loadingNextPage}
                      loadingDotsSize="13.8px"
                    />
                  </div>
                </div>
              )}
            </div>
            <Dropdown title="Possui cupom de desconto?" className={styles.cupom}>
              <div className={styles.validate + (cart.voucher ? ` ${styles.valid}` : '')}>
                <Input
                  placeholder="Insira seu cupom"
                  className={styles.input}
                  name="voucher"
                  maxLength={30}
                  value={cart.voucher?.code || fields.voucher?.value}
                  valid={voucherMessage ? (voucherMessage && cart.voucher ? true : false) : null}
                  message={voucherMessage}
                  onChange={handleChange}
                  readOnly={cart.voucher}
                />
                <div className={styles.btnWrap}>
                  <Button
                    className={styles.btn}
                    secondary
                    text={cart.voucher ? 'Remover' : 'Aplicar cupom'}
                    onClick={onClickApplyPromocode}
                    loading={loadingVoucher}
                    disabled={loadingCarriers || loadingChanges || loadingNextPage}
                    loadingDotsSize="13.8px"
                  />
                </div>
              </div>
            </Dropdown>
            {(cart.shipping || cart.voucher) && !campaignCart ? (
              <div className={styles.summary}>
                <div>
                  <h4>Subtotal</h4>
                  <h4 className={styles.price}>{formatPrice(cart.totalValue)}</h4>
                </div>
                {cart.shipping && (
                  <div>
                    <h4>Frete</h4>
                    <h4
                      className={
                        styles.price +
                        (validShippingValue(cart, campaignCart, availableCarriers[0]).isFree
                          ? ` ${styles.free}`
                          : '')
                      }
                    >
                      {validShippingValue(cart, campaignCart, availableCarriers[0]).shipmentValue}
                    </h4>
                  </div>
                )}
                {cart.voucher && cart.voucher?.discountValue > 0 ? (
                  <div>
                    <h4>Cupom</h4>
                    <h4 className={styles.price}>
                      -
                      {formatPrice(
                        calculateDiscount(
                          cart.totalValue,
                          cart.voucher.discountValue,
                          cart.voucher.percent
                        )
                      )}
                    </h4>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div
              className={
                styles.total +
                (cart.shipping?.price || cart.voucher?.discount ? ` ${styles.activeSummary}` : '')
              }
            >
              <h4>Total:</h4>
              <h4 className={styles.price}>{formatPrice(cart.finalValue)}</h4>
            </div>
            <div className={styles.btnFinishWrap}>
              <Button
                primary
                disabled={loadingCarriers || loadingVoucher || loadingChanges || invalidCart}
                loading={loadingNextPage}
                text="Ir para dados pessoais"
                className={styles.btnFinish}
                onClick={handleGoToNextPage}
              />
            </div>
          </Block>
        </div>
      </CheckoutContainer >
      {modal && (
        <Modal
          text={modal && modal.text}
          cancelText={modal?.cancelText}
          acceptText={modal?.acceptText}
          active={modal?.text ? true : false}
          onCancel={() => {
            modal.onCancel();
          }}
          onAccept={() => {
            modal.onAccept();
          }}
          closeModal={() => { }}
        />
      )}
    </>
  );
};

export default Layout;
