import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import CheckoutRoute from './CheckoutRoute';

import AwaitingPayment from '../pages/AwaitingPayment';
import { BuildKit, Edit } from '../pages/Products';
import Cart from '../pages/Cart';
import DeletedFormula from '../pages/DeletedFormula';
import FinishedCart from '../pages/FinishedCart';
import InvalidHash from '../pages/InvalidHash';
import OrderFeedback from '../pages/OrderFeedback';
import Payment from '../pages/Payment';
import PaymentProcessing from '../pages/PaymentProcessing';
import PersonalData from '../pages/PersonalData';
import ShippingInfo from '../pages/ShippingInfo';

const CheckoutRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <CheckoutRoute exact path="/" />
        <Route exact path="/feedback/processing/:orderId" component={PaymentProcessing} />
        <Route exact path="/feedback/:orderId/:status" component={OrderFeedback} />
        <Route exact path="/checkout/awaiting-payment/:orderId" component={AwaitingPayment} />
        <CheckoutRoute
          exact
          path="/checkout/payment/:token/:customerId/:addressId"
          component={Payment}
        />
        <CheckoutRoute
          exact
          path="/checkout/shipping-info/:token/:customerId"
          component={ShippingInfo}
        />
        <CheckoutRoute exact path="/checkout/personal-data/:token" component={PersonalData} />
        <CheckoutRoute exact path="/checkout/cart/:token" component={Cart} />
        <Route exact path="/cart/empty" component={DeletedFormula} />
        <Route exact path="/cart/finished" component={FinishedCart} />
        <Route exact path="/cart/invalid" component={InvalidHash} />
        <CheckoutRoute exact path="/precheckout/:token" component={BuildKit} />
        <CheckoutRoute exact path="/precheckout/:token/edit" component={Edit} />
        <Redirect
          from="/precheckout/build-kit/:token"
          to={{ pathname: '/precheckout/:token', search: window.location.search }}
        />
        <CheckoutRoute path="*" />
      </Switch>
    </BrowserRouter>
  );
};

export default CheckoutRouter;
