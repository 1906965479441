import { Component } from 'react';
import logger from './logger';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error('*Catch by Error Boundary*', error, '', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div style={{ maxWidth: '90%' }}>
            <h1 style={{ fontSize: 36 }}>Algo deu errado.</h1>
            <a style={{ fontSize: 32 }} href={process.env.REACT_APP_FORM_URL + '/new'}>
              Recomeçar jornada.
            </a>
            <p style={{ fontSize: 26 }}>
              Se recomeçar a jornada não funcionar, você pode mandar um email para nós avisando do
              problema, contato: <b>you@justfor.com.br</b>.
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
