export const formatPrice = (priceInCents) => {
  const formattedPrice = (priceInCents / 100).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedPrice;
};

export default formatPrice;
