import { CheckoutContainer, Block } from '../../components/CheckoutContainer';
import Button from '../../components/Button';
import { ReactComponent as SquareCart } from '../../assets/icons/square-cart.svg';
import styles from './FinishedCart.module.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

export const FinishedCart = () => {
  
  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <CheckoutContainer>
      <Block className={styles.block}>
        <div className={styles.wrap}>
          <h1>Seu carrinho foi finalizado</h1>
          <p>Retorne ao form para fazer outra fórmula! </p>
          <SquareCart className={styles.img} />
          <Button
            primary
            onClick={() => (window.location.href = `${process.env.REACT_APP_FORM_URL}/new`)}
            text="Fazer nova fórmula"
            className={styles.btnBackToForm}
          />
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default FinishedCart;
