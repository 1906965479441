import styles from './LoadingDots.module.css';

const LoadingDots = ({
  dotWidth = '27px',
  dotHeight = '27px',
  className,
  dotsClassName,
  ...props
}) => (
  <div className={styles.loadingDots + (className ? ' ' + className : '')} {...props}>
    <ul>
      <li style={{ width: dotWidth, height: dotHeight }} className={dotsClassName} />
      <li style={{ width: dotWidth, height: dotHeight }} className={dotsClassName} />
      <li style={{ width: dotWidth, height: dotHeight }} className={dotsClassName} />
      <li style={{ width: dotWidth, height: dotHeight }} className={dotsClassName} />
    </ul>
  </div>
);

export default LoadingDots;
