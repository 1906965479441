import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SessionContext } from '../../contexts/SessionContext';
import dataLayerHandler from '../../analytics/dataLayerHandler.js';
import {
  getProducts,
  createCart,
  formatCartPayload,
  formatCartContext,
  handleProductsChange,
  nextPageSearchParams,
} from './productsHelper';
import Layout from './Layout';
import logger from '../../utils/logger';
import notify, { EMPTY_CART } from '../../utils/notify';

export const BuildKit = () => {
  const sessionContext = useContext(SessionContext);
  const history = useHistory();
  const [shelfProducts, setShelfProducts] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [sessionForm, setSessionForm] = useState();

  const formatCart = (format, cartToken, products) => {
    if (format === 'payload') {
      return formatCartPayload(cartToken, products, sessionForm);
    } else if (format === 'context') {
      return formatCartContext(sessionContext.cart, products, sessionForm);
    }
  };

  const handleClickNext = async () => {
    setLoadingNextPage(true);
    if (cartProducts.length === 0) {
      notify(EMPTY_CART, 'info');
      setLoadingNextPage(false);
      return;
    }
    const cart = formatCart('payload', sessionContext.cart.token, cartProducts);
    const cartCreated = await createCart(cart);
    if (cartCreated) {
      logger.trace('BuildKit: Cart created');
      const updatedCart = sessionContext.updateCart(
        formatCart('context', sessionContext.cart.token, cartProducts)
      );
      const urlSearch = new URLSearchParams(window.location.search);
      const dataLayerCart = dataLayerHandler.formatCartForDataLayer(
        updatedCart,
        urlSearch.get('hairType'),
        urlSearch.get('kit')
      );
      dataLayerHandler.addToCart(dataLayerCart.products, dataLayerCart.cartToken);
      const cartSearchParams = nextPageSearchParams(new URLSearchParams(urlSearch));
      history.push('/checkout/cart/' + sessionContext.cart.token + cartSearchParams);
    } else {
      setLoadingNextPage(false);
    }
  };

  const onQuantifierChange = (productId, quantity) => {
    const productChanged = shelfProducts.find((product) => product.prd_id === productId);
    setCartProducts(handleProductsChange(cartProducts, productChanged, quantity));
  };

  const getShelfProducts = async () => {
    let products = await getProducts();
    let defaultCartProducts = [];
    let custom;
    if (sessionContext.cart.products.length > 0) custom = true;
    products.forEach((product) => {
      if (product.prd_custom && !custom) {
        product.prd_shelf = false;
      } else if (product.prd_shelf) {
        product.default = 1; // Default quantity is 1 for all products
        defaultCartProducts.push({ ...product, quantity: product.default });
      }
    });
    if (defaultCartProducts.length === 0) {
      const formSearchParams = nextPageSearchParams(
        new URLSearchParams(window.location.search),
        sessionContext.cart.token
      );
      window.location.href = `${process.env.REACT_APP_FORM_URL}/new${formSearchParams}`;
    }
    setCartProducts(defaultCartProducts);
    setShelfProducts(products);
    setLoadingProducts(false);
  };

  useEffect(() => {
    const updatedSessionForm = sessionContext.cart.products.find((form) => {
      return form.items.length === 0;
    });
    setSessionForm(updatedSessionForm);
    getShelfProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      shelfProducts={shelfProducts}
      formulaName={sessionForm?.formName}
      loadingProducts={loadingProducts}
      onQuantifierChange={onQuantifierChange}
      handleClickNext={handleClickNext}
      loadingNextPage={loadingNextPage}
    />
  );
};

export default BuildKit;
