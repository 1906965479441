import styles from './CustomRadio.module.css';

export const CustomRadio = ({ value, selected, onChange, className, disabled, ...props }) => {
  return (
    <div
      onClick={
        onChange
          ? () => {
              onChange(value);
            }
          : null
      }
      className={
        styles.radio +
        (className ? ' ' + className : '') +
        (value === selected ? ' ' + styles.selected : '') +
        (disabled ? ' ' + styles.disabled : '')
      }
      {...props}
    >
      <div className={styles.innerCircle} />
    </div>
  );
};

export default CustomRadio;
