import { generateChecksum } from './utils';

const RESERVED_NUMBERS = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

const CHECK_DIGITS_INDEXES = [9, 10];

const isValidFormat = (cpf) => {
  return /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/.test(cpf);
};

const isReservedNumber = (cpf) => {
  return RESERVED_NUMBERS.indexOf(cpf) >= 0;
};

const isValidChecksum = (cpf) => {
  return CHECK_DIGITS_INDEXES.every((i) => {
    const mod =
      generateChecksum(
        cpf
          .slice(0, i)
          .split('')
          .reduce((acc, digit) => acc + digit, ''),
        i + 1
      ) % 11;

    return cpf[i] === String(mod < 2 ? 0 : 11 - mod);
  });
};

export const isValid = (cpf) => {
  const digits = String(cpf).replace(/\D/g, '');

  return isValidFormat(cpf) && !isReservedNumber(digits) && isValidChecksum(digits);
};
