export const fullDateMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 3,
    },
    regexCollector: /^(\d{2})(\d{0,2})$/m,
    replacement: '$1/$2',
  },
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 5,
    },
    regexCollector: /^(\d{2})(\d{2})(\d{1,4})?$/m,
    replacement: '$1/$2/$3',
  },
];

export const shortDateMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 3,
    },
    regexCollector: /^(\d{2})(\d{1,2})?$/m,
    replacement: '$1/$2',
  },
];

export const cpfCnpjMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '===',
      value: 11,
    },
    regexCollector: /^(\d{3})(\d{3})(\d{3})(\d{2})$/m,
    replacement: '$1.$2.$3-$4',
  },
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>',
      value: 11,
    },
    regexCollector: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/m,
    replacement: '$1.$2.$3/$4-$5',
  },
];

export const cpfMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>',
      value: 3,
    },
    regexCollector: /^(\d{3})(\d{1,3})$/m,
    replacement: '$1.$2',
  },
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>',
      value: 6,
    },
    regexCollector: /^(\d{3})(\d{3})(\d{1,3})$/m,
    replacement: '$1.$2.$3',
  },
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 10,
    },
    regexCollector: /^(\d{3})(\d{3})(\d{3})(\d{1,2})$/m,
    replacement: '$1.$2.$3-$4',
  },
];

export const cellphoneMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 10,
    },
    regexCollector: /^(\d{2})(\d{4,5})(\d{4})$/m,
    replacement: '($1) $2-$3',
  },
];

export const cepMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 6,
    },
    regexCollector: /^(\d{5})(\d{1,3})$/m,
    replacement: '$1-$2',
  },
];

export const creditCardNumberMask = [
  {
    onlyDigit: true,
    formatWhen: {
      condition: '>=',
      value: 4,
    },
    regexCollector: /^(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})$/m,
    replacement: '$1 $2 $3 $4',
    trim: true,
  },
];

export const noSpaceMask = [
  {
    trim: true,
  },
];
