import { useState, useEffect } from 'react';
import getSuggestion from './suggestions';
import { ReactComponent as ValidContentSvg } from '../../assets/icons/valid.svg';
import { ReactComponent as InvalidContentSvg } from '../../assets/icons/invalid.svg';
import styles from './Input.module.css';

const Input = ({
  placeholder = 'Normal',
  type = 'text',
  disabled = false,
  onlyDigit = false,
  value = '',
  valid,
  message,
  onChange,
  onBlur,
  onSuggestion,
  noSpace,
  maxLength,
  className,
  inputClassName,
  prepend,
  ...props
}) => {
  const formatInput = (inputValue) => {
    if (onlyDigit) {
      inputValue = inputValue.replace(/\D/g, '');
    }

    if (noSpace && !onlyDigit) {
      inputValue = inputValue.replace(/\s/g, '');
    }

    if (maxLength) {
      inputValue = inputValue.slice(0, maxLength);
    }

    return inputValue;
  };

  const [inputContent, setInputContent] = useState(formatInput(value));
  const [suggestion, setSuggestion] = useState();

  const handleChange = (event) => {
    event.target.value = formatInput(event.target.value);

    if (onSuggestion) {
      setSuggestion();
    }

    if (onChange) {
      onChange?.(event);
    } else {
      setInputContent(event.target.value);
    }
  };

  const handleBlur = (event) => {
    if (onSuggestion) {
      setSuggestion(getSuggestion(type, event.target.value));
    }

    if (onBlur) {
      onBlur?.(event);
    }
  };

  useEffect(() => {
    setInputContent(value);
  }, [maxLength, value]);

  return (
    <div className={className}>
      <div
        className={
          styles.container +
          (valid ? ' ' + styles.valid : '') +
          (valid === false ? ' ' + styles.invalid : '')
        }
      >
        {prepend && <div className={styles.prepend}>{prepend}</div>}
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={
            styles.input +
            (inputClassName ? ' ' + inputClassName : '') +
            (disabled ? ' ' + styles.disabled : '')
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={inputContent}
          {...props}
        ></input>
        {valid && (
          <ValidContentSvg className={styles.validationIcon} alt="Indicador de conteúdo válido" />
        )}
        {valid === false && (
          <InvalidContentSvg className={styles.validationIcon} alt="Indicador de conteúdo válido" />
        )}
      </div>
      {valid !== undefined && message && (
        <p
          className={
            styles.message +
            ' ' +
            (valid ? ' ' + styles.valid : '') +
            (valid === false ? ' ' + styles.invalid : '')
          }
        >
          {message}
        </p>
      )}
      {suggestion && (
        <p className={styles.suggestions}>
          Você quis dizer{' '}
          <b
            onClick={() => {
              onSuggestion(suggestion);
              setSuggestion(getSuggestion(type, suggestion));
            }}
          >
            {suggestion}
          </b>
          ?
        </p>
      )}
    </div>
  );
};

export default Input;
