import { useState, useEffect } from 'react';
import { ReactComponent as Icon } from '../../assets/icons/arrow-up.svg';
import styles from './Dropdown.module.css';

const Dropdown = ({
  children,
  title = 'Dropdown',
  disabled = false,
  collapsed = false,
  className,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(disabled ? true : collapsed);

  useEffect(() =>{
    setIsCollapsed(collapsed);
  }, [collapsed]);

  return (
    <div className={className}>
      <div
        className={
          styles.dropdown +
          (disabled ? ' ' + styles.disabled : '')
        }
        onClick={() => (!disabled) && setIsCollapsed(!isCollapsed)}
        {...props}
      >
        <div className={styles.title}>{title}</div>
        <Icon className={styles.icon + (isCollapsed ? '' : ' ' + styles.active)} />
      </div>
      <div className={styles.content + (isCollapsed ? '' : ' ' + styles.active)}>{children}</div>
    </div>
  );
};

export default Dropdown;
