export const getKitDefaultDataLayerItem = (hairType) => {
  return [
    {
      item_id: 'kit' + (hairType ? '-' + hairType : ''),
      item_name: 'Kit Personalizado',
      price: 274.7,
      quantity: 1,
    },
  ];
};

// Uses new sessionContext cart format
export const formatCartForDataLayer = (cart, hairType, kit = false) => {
  let dataLayerCart = {
    cartToken: cart.token,
    value: cart.finalValue,
    products: [],
  };
  if (!hairType) hairType = '';

  if (!kit) {
    cart.products.forEach((form) => {
      form.items.forEach((item) => {
        const index = dataLayerCart.products.findIndex(
          (dataLayerItem) => dataLayerItem.item_id === item.id.toString()
        );
        if (index !== -1) {
          dataLayerCart.products[index].quantity += item.quantity;
        } else {
          dataLayerCart.products.push({
            item_id: item.id.toString() + (hairType ? '-' + hairType : ''),
            item_name: item.name,
            price: item.value / 100,
            quantity: item.quantity,
          });
        }
      });
    });
  } else {
    dataLayerCart.products = getKitDefaultDataLayerItem(hairType);
  }
  if (cart?.voucher) {
    dataLayerCart.coupon = cart.voucher.code;
  }

  return dataLayerCart;
};

// Uses DB cart format
export const formatDataLayerCartItems = (cart, hairType, kit = false) => {
  let dataLayerItems = [];
  if (!hairType) hairType = '';
  if (!kit) {
    cart.products.forEach((form) => {
      form.items.forEach((product) => {
        let existingProduct = dataLayerItems.findIndex((item) => item.item_id === product.product_id);
        if (existingProduct !== -1) {
          dataLayerItems[existingProduct].quantity += product.quantity;
        } else {
          dataLayerItems.push({
            item_name: product.product_name,
            item_id: product.product_id + (hairType ? '-' + hairType : ''),
            price: product.value / 100,
            quantity: product.quantity,
          });
        }
      });
    });
  } else {
    dataLayerItems = getKitDefaultDataLayerItem(hairType);
  }
  return dataLayerItems;
};
