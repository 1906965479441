import { generateChecksum } from './utils';

const RESERVED_NUMBERS = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999',
];

const CHECK_DIGITS_INDEXES = [12, 13];

const FIRST_CHECK_DIGIT_WEIGHTS = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

const isValidFormat = (cnpj) => {
  return /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/.test(cnpj);
};

const isReservedNumber = (cpf) => {
  return RESERVED_NUMBERS.indexOf(cpf) >= 0;
};

const isValidChecksum = (cnpj) => {
  const weights = [...FIRST_CHECK_DIGIT_WEIGHTS];

  return CHECK_DIGITS_INDEXES.every((i) => {
    if (i === CHECK_DIGITS_INDEXES[CHECK_DIGITS_INDEXES.length - 1]) {
      weights.unshift(6);
    }

    const mod =
      generateChecksum(
        cnpj
          .slice(0, i)
          .split('')
          .reduce((acc, digit) => acc + digit, ''),
        weights
      ) % 11;

    return cnpj[i] === String(mod < 2 ? 0 : 11 - mod);
  });
};

export const isValid = (cnpj) => {
  const numbers = String(cnpj).replace(/\D/g, '');

  return isValidFormat(cnpj) && !isReservedNumber(numbers) && isValidChecksum(numbers);
};
