import { FIELD_REQUIRED } from './messages';

export const validator = async (valueToValidate, required, schema = undefined) => {
  if (required && (!valueToValidate || valueToValidate?.trim?.() === '')) {
    return {
      valid: false,
      message: FIELD_REQUIRED,
    };
  }

  if (schema) {
    return schema
      .validate(valueToValidate, {
        abortEarly: true,
      })
      .then(() => ({
        valid: true,
        message: '',
      }))
      .catch((error) => ({
        valid: false,
        message: error.message,
      }));
  } else {
    return {
      valid: true,
      details: 'No schema',
      message: '',
    };
  }
};

export * from './schemas';
export default validator;
