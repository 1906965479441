import { useEffect, useRef } from 'react';
import styles from './Modal.module.css';

const Modal = ({
  text = 'Modal Example',
  acceptText = 'OK',
  cancelText = 'Cancel',
  onAccept,
  onCancel,
  active = true,
  closeModal,
}) => {
  const modalWrap = useRef();
  const modalBody = useRef();

  useEffect(() => {
    if (active) {
      modalWrap.current.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        if (!modalBody?.current?.contains(e.target)) {
          closeModal();
        }
      });
    }
  }, [closeModal, active]);

  if (!active) {
    return null;
  }
  return (
    <div className={styles.modal + (active ? ` ${styles.active}` : '')} ref={modalWrap}>
      <div className={styles.content} ref={modalBody}>
        <p className={styles.text}>{text}</p>
        <div className={styles.actions}>
          <p onClick={onCancel && onCancel} className={styles.cancel}>
            {cancelText}
          </p>
          <p onClick={onAccept && onAccept} className={styles.accept}>
            {acceptText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
