export const formater = (value, mask) => {
  mask.forEach((rule) => {
    let rawValue = value;

    if (rule.onlyDigit) {
      rawValue = value.replace(/\D/g, '');
    }

    let lenghtCondition;
    switch (rule.formatWhen?.condition) {
      case '===':
        lenghtCondition = rawValue.length === rule.formatWhen.value;
        break;
      case '>':
        lenghtCondition = rawValue.length > rule.formatWhen.value;
        break;
      case '>=':
        lenghtCondition = rawValue.length >= rule.formatWhen.value;
        break;
      case '<':
        lenghtCondition = rawValue.length < rule.formatWhen.value;
        break;
      case '<=':
        lenghtCondition = rawValue.length <= rule.formatWhen.value;
        break;
      default:
        lenghtCondition = false;
    }

    if (lenghtCondition) {
      value = rawValue.replace(rule.regexCollector, rule.replacement);
    }

    if (rule.trim) {
      value = value.trim();
    }
  });

  return value;
};

export * from './masks';
export default formater;
