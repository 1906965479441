const getUrlParams = (
  searchParams: URLSearchParams,
  paramsArray: Array,
  allParams: Boolean = false // Get all params
) => {
  const params = {};
  searchParams.forEach((value, key) => {
    if (paramsArray.includes(key) && !allParams) {
      params[key] = value;
    } else if (allParams) {
      params[key] = value;
    }
  });
  return params;
};

const stringFromParamsObject = (
  paramsObject: Object,
  paramsToInclude: Array<String> = [],
  paramsToExclude: Array<String> = []
) => {
  let paramsArray = [];
  const include = paramsToInclude.length > 0;
  const exclude = paramsToExclude.length > 0;
  Object.entries(paramsObject).forEach(([param, value]) => {
    let string = '';
    if (include || exclude) {
      if (include) {
        if (paramsToInclude.includes(param)) {
          string = param + '=' + value;
        }
      } else {
        string = param + '=' + value;
      }
      if (exclude) {
        if (paramsToExclude.includes(param)) {
          string = '';
        }
      }
      paramsArray.push(string);
    } else {
      paramsArray.push(param + '=' + value);
    }
  });

  let finalStringParams = '';
  if (paramsArray.length !== 0) {
    finalStringParams = '?' + paramsArray.filter((param) => param !== '').join('&');
  }

  return finalStringParams;
};

export { getUrlParams, stringFromParamsObject };
