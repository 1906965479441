import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../contexts/SessionContext';
import { useHistory } from 'react-router-dom';
import checkoutBackend from '../../services/checkoutBackend';
import { CheckoutContainer, Block } from '../../components/CheckoutContainer';
import { ChoiceGroup, Choice } from '../../components/ChoiceGroup';
import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import { Select } from '../../components/Select';
import { ReactComponent as TruckIcon } from '../../assets/truck.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/infoIcon.svg';
import useForm from '../../hooks/useForm';
import logger from '../../utils/logger';
import dataLayerHandler from '../../analytics/dataLayerHandler.js';
import { formatPrice } from '../../utils/formatPrice';
import { cepMask } from '../../utils/formater';
import { cepSchema, streetSchema, numberSchema, complementSchema, neighborhoodSchema } from '../../utils/validator';
import { formatCartFinish } from '../Cart/cartHelper';
import { validateZipcode, fomartShippingInfoLimitCaracters } from './ShippingInfoHelpers';
import cep from 'cep-promise';
import ReactGA from 'react-ga';

import styles from './ShippingInfo.module.css';
import { getKitDefaultDataLayerItem } from '../../analytics/utils';
import notify, { INVALID_ADDRESS } from '../../utils/notify';

const fetchAddress = async (zipcode) => {
  return cep(zipcode).catch((error) => {
    logger.warn('Error fetching the address\n', error);
    return { invalid: true };
  });
};

export const ShippingInfo = (args) => {
  const history = useHistory();
  const cartToken = args.computedMatch.params.token;
  const customerId = args.computedMatch.params.customerId;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const sessionContext = useContext(SessionContext);
  const [carriers, setCarriers] = useState([]);
  const [chosenCarrier, setChosenCarrier] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [zipcodeIsValidToShipping, setZipcodeIsValidToShipping] = useState(true);
  const ufData = [
    { value: '', label: 'Selecione o estado' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo	' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'PR', label: 'Paraná' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const [fieldsReadOnly, setFieldsReadOnly] = useState({
    city: false,
    neighborhood: false,
    uf: false,
    street: false,
    postalcode: false,
  });
  const [cartInfos, setCartInfos] = useState([]);

  const { fields, handleChange, handleFinish, formIsInvalid, setFieldValue } = useForm({
    postalcode: {
      required: true,
      mask: cepMask,
      schema: cepSchema,
    },
    city: {
      required: true,
    },
    uf: {
      required: true,
    },
    street: {
      required: true,
      schema: streetSchema,
    },
    number: {
      required: true,
      schema: numberSchema,
    },
    neighborhood: {
      required: true,
      schema: neighborhoodSchema,
    },
    complement: {
      required: false,
      schema: complementSchema,
    },
    reference: {
      required: false,
    },
    carrier: {
      required: true,
    },
  });

  const redirectToCorreiosPage = () => {
    window.open(process.env.REACT_APP_URL_PAGE_CORREIOS);
  };

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handlePostalCodeChange = async (event) => {
    const postalcode = event.target.value;
    setFieldValue('postalcode', postalcode);

    if (postalcode.length === 0) {
      setFieldValue('city', '');
      setFieldValue('uf', '');
      setFieldValue('street', '');
      setFieldValue('neighborhood', '');
      setFieldValue('postalcode', '');
      setFieldValue('number', '');
      setFieldValue('complement', '');
      setFieldValue('reference', '');

      setFieldsReadOnly({
        city: false,
        uf: false,
        street: false,
        neighborhood: false,
        postalcode: false,
      });
    } else if (postalcode.length === 8) {
      const zipcodeIsValid = await validateZipcode(postalcode);
      if (!zipcodeIsValid) return notify(INVALID_ADDRESS, 'warn');
      const { city, neighborhood, state, street } = await fetchAddress(postalcode);
      setZipCode(postalcode.replace(/[^0-9]/g, ''));

      setFieldsReadOnly({
        city: Boolean(city),
        uf: Boolean(state),
        street: Boolean(street),
        neighborhood: Boolean(neighborhood),
        postalcode: Boolean(sessionContext.cart.voucher?.zip_limitation),
      });

      setFieldValue('city', city);
      setFieldValue('uf', state);
      setFieldValue('street', fomartShippingInfoLimitCaracters(street,50));
      setFieldValue('neighborhood', fomartShippingInfoLimitCaracters(neighborhood,40));
      setFieldValue('postalcode', postalcode, true);
    }
  };

  const handleValidateZipcode = async (zipCode, carriers) => {
    const zipCodeIsValid = await validateZipcode(zipCode);
    const isValidToShipping = zipCodeIsValid && carriers.length > 0;

    setZipcodeIsValidToShipping(isValidToShipping);
    if (!isValidToShipping) return notify(INVALID_ADDRESS, 'error');
    setCarriers(carriers);
  };

  const validShippingValue = (cart, campaignCart, carrier) => {
    if (cart?.voucher?.freeShipping || campaignCart) return { isFree: true, shipmentValue: 'Grátis' }
    if (cart?.shipping?.fee === 0) return { isFree: true, shipmentValue: 'Grátis' }
    if (cart?.voucher?.zip_limitation && cart?.voucher?.shipment_value === 0) return { isFree: true, shipmentValue: 'Grátis' }
    if (cart?.voucher?.zip_limitation && cart?.voucher?.shipment_value > 0) return { isFree: false, shipmentValue: formatPrice(cart.voucher?.shipment_value) }

    if (carrier) return { isFree: false, shipmentValue: formatPrice(carrier.v_valfee) }
    return { isFree: false, shipmentValue: formatPrice(cart?.shipping?.fee || cartInfos.cart?.cart_shippment_fee) }
  }

  useEffect(() => {
    const setAdditionalAddressInfo = () => {
      checkoutBackend(`/logistics/address/cart/${cartToken}`, 'GET', {}, true)
        .then((response) => {
          if (response.number) {
            setFieldValue('number', response.number.toString());
            setFieldValue('complement', response.complement);
            setFieldValue('reference', response.reference_point);
          }
        })
        .catch((error) => {
          logger.error(
            'Failed to set additional address info\n',
            error,
            '\n',
            error.responseJson || null
          );
        });
    };

    const getAddress = async () => {
      checkoutBackend('/carts/' + cartToken, 'GET', {}, true)
        .then(async (response) => {
          setCartInfos(response.result);
          let _zipCode = response.result.cart.cart_zipcode;
          setZipCode(_zipCode);
          if (!fields.postalcode?.value) {
            const postalcode = _zipCode;
            const { city, neighborhood, state, street } = await fetchAddress(postalcode);

            setFieldsReadOnly({
              city: Boolean(city),
              uf: Boolean(state),
              street: Boolean(street),
              neighborhood: Boolean(neighborhood),
              postalcode: Boolean(sessionContext.cart.voucher?.zip_limitation),
            });

            setFieldValue('city', city);
            setFieldValue('uf', state);
            setFieldValue('street', fomartShippingInfoLimitCaracters(street,50));
            setFieldValue('neighborhood', fomartShippingInfoLimitCaracters(neighborhood,40));
            setFieldValue('postalcode', postalcode, true);
            setFieldValue('carrier', response.result.cart.carrier_name);
            setAdditionalAddressInfo();
          }
        })
        .catch((error) => {
          logger.error('Failed to get address\n', error, '\n', error.responseJson || null);
        });
    };

    if (fields.postalcode?.value !== '') {
      getAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartToken]);

  useEffect(() => {
    if (!zipCode) return;
    if (zipCode.length < 8) return;

    checkoutBackend('/logistics/carrier/zip/' + zipCode + '/cart/' + cartToken, 'GET', {}, true)
      .then((response) => {
        handleValidateZipcode(zipCode, response);
      })
      .catch((error) => {
        logger.error('Failed to get carriers\n', error, '\n', error.responseJson || null);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  const handleGoToNextPage = async () => {
    setLoadingNextPage(true);
    const { valid, details } = await handleFinish();
    logger.trace('handleGoToNextPage', valid, fields, details);
    if (valid) {
      const zipcodeIsValid = await validateZipcode(zipCode);
      if (!zipcodeIsValid) {
        setLoadingNextPage(false);
        return notify(INVALID_ADDRESS, 'error');
      }

      //if zipcode changed, finish cart and revalidate all again
      let selectedCarrier = {
        id: cartInfos.cart.carrier_id,
        price: cartInfos.cart.cart_shippment_fee,
        cost: cartInfos.cart.cart_shipment_cost,
      };

      logger.debug(selectedCarrier);
      if (cartInfos?.cart.cart_zipcode !== zipCode) {
        if (!chosenCarrier.id) {
          if (cartInfos?.voucher?.free_shipment) selectedCarrier.price = 0;
          if (cartInfos?.voucher?.zip_limitation)
            selectedCarrier.price = cartInfos.voucher?.shipment_value;
          if (!cartInfos?.voucher?.zip_limitation && !cartInfos?.voucher?.free_shipment)
            selectedCarrier.price = carriers[0].v_valfee;

          selectedCarrier.id = carriers[0].v_carrier_id;
          selectedCarrier.cost = carriers[0].v_valfee;
        }
      }

      const preRequestBody = formatCartFinish({
        ...sessionContext.cart,
        shipping: { carrier: selectedCarrier.id, fee: selectedCarrier.cost, zipcode: zipCode },
      });

      const hairType = urlSearchParams.get('hairType');
      let dataLayerItems = [];
      if (!urlSearchParams.get('kit')) {
        cartInfos.products.forEach((form) => {
          form.items.forEach((product) => {
            let existingProduct = dataLayerItems.findIndex((item) => item.item_id === product.sku);
            if (existingProduct !== -1) {
              dataLayerItems[existingProduct].quantity += product.quantity;
            } else {
              dataLayerItems.push({
                item_name: product.product_name,
                item_id: product.product_id + (hairType ? '-' + hairType : ''),
                price: product.value / 100,
                quantity: product.quantity,
              });
            }
          });
        });
      } else {
        dataLayerItems = getKitDefaultDataLayerItem(hairType);
      }

      let dataLayerValue =
        (cartInfos.total_value - (cartInfos.discounts_value || 0) + selectedCarrier.price) / 100;

      dataLayerHandler.addShippingInfo(
        dataLayerItems,
        dataLayerValue,
        cartInfos?.voucher ? cartInfos?.voucher.code : '',
        carriers.find((carrier) => carrier.v_carrier_id === selectedCarrier.id).v_carrier_alias,
        cartToken
      );

      checkoutBackend(`/carts/${cartToken}/finish`, 'PATCH', preRequestBody, true)
        .then(() => {
          // save infos
          let parsed_zipcode = fields.postalcode.value.replace('-', '');
          let reqBody = {
            customer_id: customerId,
            customer_address: fields.street.value,
            customer_state: fields.uf.value.toUpperCase(),
            customer_district: fields.neighborhood.value,
            customer_number: fields.number.value,
            customer_city: fields.city.value,
            customer_zip: parsed_zipcode,
            customer_reference: fields.reference.value,
            customer_complement: fields.complement.value,
            cart_id: cartToken,
          };

          checkoutBackend('/logistics/address/customer', 'POST', reqBody, true)
            .then((response) => {
              history.push(
                `/checkout/payment/${cartToken}/${customerId}/${response}${urlSearchParams.toString ? '?' + urlSearchParams : ''
                }`
              );
            })
            .catch((error) => {
              setLoadingNextPage(false);
              logger.error(
                'Failed to save shipping address\n',
                error,
                '\n',
                error.responseJson || null,
                '\nBody of the Request:',
                reqBody
              );
            });
        })
        .catch((error) => {
          setLoadingNextPage(false);
          logger.error(
            'Failed to finish cart\n',
            error,
            '\n',
            error.responseJson || null,
            '\nBody of the Request:',
            preRequestBody
          );
        });
    } else {
      logger.trace('Ops, você precisa responder todos os campos obrigatórios antes de prosseguir');
      setLoadingNextPage(false);
    }
  };

  const renderShipmentOptions = () => {
    if (carriers.length > 0) {
      return carriers.map((element, index) => {
        let value = formatPrice(element.v_valfee);
        return (
          <Choice
            id={index}
            key={index}
            title={element.v_carrier_alias}
            description={
              'Entregue em até ' +
              element.v_expday +
              (element.v_expday > 1 ? ' dias úteis' : ' dia útil')
            }
            info={validShippingValue(sessionContext.cart || cartInfos, sessionContext?.campaignHash, false).shipmentValue}
            infoClassName={
              value === 0 ||
                validShippingValue(sessionContext.cart || cartInfos, sessionContext?.campaignHash, false).isFree
                ? 'color-green-8'
                : ''
            }
          ></Choice>
        );
      });
    }
    return null;
  };

  const renderChosenShipmentValue = () => {
    if (cartInfos?.cart?.cart_zipcode === zipCode) {
      if (cartInfos?.cart) {
        return validShippingValue(sessionContext?.cart || cartInfos, sessionContext?.campaignHash, false).shipmentValue;
      } else {
        return '';
      }
    } else {
      if (carriers.length > 0) {
        return validShippingValue(sessionContext?.cart || cartInfos, sessionContext?.campaignHash, carriers[0]).shipmentValue;
      }
    }
    return null;
  };

  const renderChosenShipmentName = () => {
    if (cartInfos?.cart?.cart_zipcode === zipCode) {
      return cartInfos?.cart ? cartInfos?.cart.carrier_name : '';
    } else {
      if (carriers.length > 0) {
        return carriers[0].v_carrier_alias;
      }
    }
    return null;
  };

  return (
    <CheckoutContainer
      step={3}
      cartToken={cartToken}
      className={styles.shippingInfo}
      customerId={customerId}
    >
      <Block title='Dados para entrega' className={styles.leftBlock}>
        <div className={styles.shippingData}>
          <div className={styles.w50}>
            <h2 className={styles.firstLabel}>
              CEP{' '}
              <InfoIcon className={styles.infoZipcode} onClick={() => redirectToCorreiosPage()} />
            </h2>
            <Input
              type='tel'
              name='postalcode'
              onlyDigit
              maxLength={8}
              readOnly={fieldsReadOnly.postalcode}
              className={styles.shortInput}
              placeholder='Seu CEP'
              autoComplete='shipping postal-code'
              value={fields.postalcode?.value}
              valid={!fields.postalcode?.valid ? fields.postalcode?.valid : null}
              onChange={handlePostalCodeChange}
              message={fields.postalcode?.message}
              disabled={loadingNextPage}
            />
          </div>
          <div className={styles.grid}>
            <div className={styles.city}>
              <h2 className={styles.label}>Cidade</h2>
              <Input
                className={styles.shortInput}
                placeholder='Nome da cidade'
                name='city'
                autoComplete='shipping address-level2'
                readOnly={fieldsReadOnly.city}
                value={fields.city?.value}
                message={fields.city?.message}
                valid={!fields.city?.valid ? fields.city?.valid : null}
                maxLength={250}
                onChange={handleChange}
                disabled={loadingNextPage}
              />
            </div>
            <div className={styles.state + ' ' + styles.w50}>
              <h2 className={styles.label}>Estado</h2>
              <Select
                name='uf'
                value={ufData.find((state) => state.value === fields.uf?.value)?.value}
                disabled={fieldsReadOnly.uf}
                valid={!fields.uf?.valid ? fields.uf?.valid : null}
                message={fields.uf?.message}
                onChange={handleChange}
                data={ufData}
                className={styles.select}
              />
            </div>
          </div>
          <h2 className={styles.label}>Endereço</h2>
          <Input
            className={styles.input}
            placeholder='Seu endereço'
            autoComplete='shipping address-line1'
            name='street'
            value={fields.street?.value}
            maxLength={50}
            onChange={handleChange}
            message={fields.street?.message}
            valid={!fields.street?.valid ? fields.street?.valid : null}
            readOnly={fieldsReadOnly.street}
            disabled={loadingNextPage}
          />
          <div className={styles.grid}>
            <div className={styles.number}>
              <h2 className={styles.label}>Número</h2>
              <Input
                className={styles.shortInput}
                placeholder='Nº Residência'
                name='number'
                value={fields.number?.value}
                message={fields.number?.message}
                valid={!fields.number?.valid ? fields.number?.valid : null}
                maxLength={10}
                onChange={handleChange}
                disabled={loadingNextPage}
              />
            </div>
            <div className={styles.neighborhood}>
              <h2 className={styles.label}>Bairro</h2>
              <Input
                className={styles.shortInput}
                placeholder='Seu bairro'
                name='neighborhood'
                autoComplete='shipping address-level3'
                value={fields.neighborhood?.value}
                message={fields.neighborhood?.message}
                valid={!fields.neighborhood?.valid ? fields.neighborhood?.valid : null}
                maxLength={40}
                onChange={handleChange}
                readOnly={fieldsReadOnly.neighborhood}
                disabled={loadingNextPage}
              />
            </div>
          </div>
          <div className={styles.gridDesktop}>
            <div className={styles.w50Desktop}>
              <h2 className={styles.label}>Complemento</h2>
              <Input
                className={styles.input}
                placeholder='Apartamento, Edifício, Casa, etc. (opcional)'
                name='complement'
                autoComplete='shipping address-line2'
                value={fields.complement?.value}
                message={fields.complement?.message}
                valid={!fields.complement?.valid ? fields.complement?.valid : null}
                maxLength={30}
                onChange={handleChange}
                disabled={loadingNextPage}
              />
            </div>
            <div className={styles.w50Desktop}>
              <h2 className={styles.label}>Pto. de referência</h2>
              <Input
                className={styles.input}
                placeholder='Um shopping, um mercado etc. (opcional)'
                name='reference'
                autoComplete='shipping address-line3'
                value={fields.reference?.value}
                message={fields.reference?.message}
                valid={!fields.reference?.valid ? fields.reference?.valid : null}
                maxLength={350}
                onChange={handleChange}
                disabled={loadingNextPage}
              />
            </div>
          </div>
        </div>
      </Block>
      <Block className={styles.rightBlock}>
        <div className={styles.shipping}>
          <div className={styles.title}>
            <TruckIcon className={styles.truckIcon} />
            <h4>Meio de envio</h4>
          </div>
          {zipcodeIsValidToShipping ? (
            <>
              <p className={styles.zipcode}>
                Entrega para o CEP: <span>{fields.postalcode.value}</span>
              </p>
              <div className={styles.chosenMethod}>
                <div className={styles.nameAndDesc}>
                  <h2>{cartInfos?.cart ? renderChosenShipmentName() : null}</h2>
                  <p>{`Entregue em até ${cartInfos?.cart?.carrier_days} dias úteis`}</p>
                </div>
                <h3
                  className={
                    styles.price +
                    (cartInfos?.voucher?.free_shipment ||
                      cartInfos?.cart?.cart_shippment_fee === 0 ||
                      (cartInfos?.voucher?.zip_limitation && cartInfos?.voucher?.shipment_value === 0)
                      ? styles.colorFreeShipment
                      : '')
                  }
                >
                  {cartInfos?.cart ? renderChosenShipmentValue() : null}
                </h3>
              </div>
              <Dropdown className={styles.dropdown} title='Alterar forma de entrega' collapsed>
                <h3>Escolha uma forma de entrega</h3>
                {carriers.length > 0 ? (
                  <ChoiceGroup
                    selected={carriers.length === 1 && carriers[0].v_carrier_alias}
                    onChange={(carrier) => {
                      setFieldValue('carrier', carrier);
                      setChosenCarrier({
                        name: carrier,
                        price: validShippingValue(sessionContext.cart || cartInfos, sessionContext?.campaignHash, false).isFree
                          ? 0
                          : carriers.find((carr) => carr.v_carrier_alias === carrier).v_valfee,
                        id: carriers.find((carr) => carr.v_carrier_alias === carrier).v_carrier_id,
                        cost: carriers.find((carr) => carr.v_carrier_alias === carrier).v_valfee,
                      });
                    }}
                  >
                    {renderShipmentOptions()}
                  </ChoiceGroup>
                ) : (
                  'Carregando'
                )}
              </Dropdown>
            </>
          ) : (
            <p className={styles.zipcodeNotFound}>CEP não encontrado</p>
          )}
        </div>
      </Block>
      <Block className={styles.leftBlock}>
        <div className={styles.buttonWrap}>
          <Button
            className={styles.button}
            disabled={formIsInvalid}
            primary
            text='Ir para pagamento'
            onClick={handleGoToNextPage}
            loading={loadingNextPage}
          />
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default ShippingInfo;
