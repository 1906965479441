class Product {
  constructor(
    id: BigInt,
    sku: String,
    name: String,
    displayName: String,
    description: String,
    value: BigInt,
    custom: Boolean,
    brand: String,
    image: String,
    shelf: Boolean,
    quantity: BigInt,
    benefit: Boolean,
    size: String,
    weight: BigInt
  ) {
    this.id = id;
    this.sku = sku;
    this.name = name;
    this.displayName = displayName;
    this.description = description ? description : null;
    this.value = value ? value : 0;
    this.custom = custom ? true : false;
    this.brand = brand ? brand : null;
    this.image = image;
    this.shelf = shelf;
    this.quantity = quantity;
    this.benefit = benefit;
    this.size = size;
    this.weight = weight ? weight : 0;
  }
}

class Form {
  constructor(
    formId: String | null = null,
    formName: String | null = null,
    items: Array<Product> = []
  ) {
    this.formId = formId;
    this.formName = formName;
    this.items = items;
  }
}

class Shipping {
  constructor(zipcode: String, fee: BigInt, carrier: BigInt) {
    this.zipcode = zipcode;
    this.fee = fee;
    this.carrier = carrier;
  }
}

class Voucher {
  constructor(
    id: String,
    code: String,
    discountValue: BigInt,
    percent: Boolean,
    freeShipping: Boolean,
    shipment_value: BigInt,
    zip_limitation: Boolean,
    benefits: Array<BigInt>
  ) {
    this.id = id;
    this.code = code;
    this.discountValue = discountValue;
    this.percent = percent;
    this.freeShipping = freeShipping;
    this.shipment_value = shipment_value;
    this.zip_limitation = zip_limitation;
    this.benefits = benefits;
  }
}

class Cart {
  constructor(
    token: String,
    products: Array<Form> = [],
    totalValue: BigInt = 0,
    voucher: Voucher,
    shipping: Shipping,
    finalValue: BigInt = 0
  ) {
    this.token = token;
    this.products = products;
    this.totalValue = totalValue;
    this.voucher = voucher;
    this.shipping = shipping;
    this.finalValue = finalValue;
  }
}

export { Cart, Form, Product, Voucher, Shipping };
