import checkoutBackend from '../../services/checkoutBackend.js';
import { getUrlParams, stringFromParamsObject } from '../../utils/urlParamsHandler';
import logger from '../../utils/logger';

export const createCart = async (cart) => {
  try {
    await checkoutBackend('/carts', 'POST', cart, true);
    return true;
  } catch (error) {
    logger.error(error);
    return false;
  }
};

export const updateCart = async (cart) => {
  try {
    await checkoutBackend('/carts/' + cart.token, 'PATCH', cart, true);
    return true;
  } catch (error) {
    logger.error(error);
    return false;
  }
};

export const getProducts = async (sort = true) => {
  let products = [];
  try {
    products = await checkoutBackend('/products', 'GET', {}, true);
  } catch (error) {
    logger.error(error);
  }
  if (sort) products.sort((a, b) => a.prd_id - b.prd_id); // Sort by ID
  return products;
};

// UTILS

export const handleProductsChange = (cartProducts, product, quantity) => {
  const updatedCartProducts = cartProducts;
  if (quantity > 0) {
    const updatedProduct = { ...product, quantity };
    const index = updatedCartProducts.findIndex(
      (cartProduct) => cartProduct.prd_id === product.prd_id
    );
    if (index === -1) {
      updatedCartProducts.push(updatedProduct);
    } else {
      updatedCartProducts[index] = updatedProduct;
    }
  } else {
    const index = updatedCartProducts.findIndex(
      (cartProduct) => cartProduct.prd_id === product.prd_id
    );
    updatedCartProducts.splice(index, 1);
  }
  return updatedCartProducts;
};

export const nextPageSearchParams = (searchParams, cartToken) => {
  let paramsObject = {
    ...getUrlParams(searchParams, [], true),
  };

  if (cartToken) paramsObject.cartId = cartToken;

  const string = stringFromParamsObject(paramsObject, [], ['form', 'formula_name']);

  return string;
};

// FORMATTERS

export const formatProductPayload = (product_id, quantity, custom) => {
  return {
    product_id: product_id,
    quantity: quantity,
    custom: custom,
  };
};

export const formatCartPayload = (cartToken, products, sessionForm) => {
  let cart = {};
  cart.token = cartToken;
  cart.products = [];

  products.forEach((product) => {
    if (product.quantity === 0) return;

    const formattedProduct = formatProductPayload(
      product.prd_id,
      product.quantity,
      product.prd_custom
    );

    if (product.prd_custom) {
      const index = cart.products.findIndex((form) => form?.form_id === sessionForm.formId);
      if (index !== -1) {
        cart.products[index].products.push(formattedProduct);
      } else {
        cart.products.push({
          form_id: sessionForm.formId,
          products: [formattedProduct],
        });
      }
    } else {
      const index = cart.products.findIndex((form) => !form.form_id);
      if (index !== -1) {
        cart.products[index].products.push(formattedProduct);
      } else {
        cart.products.push({
          form_id: null,
          products: [formattedProduct],
        });
      }
    }
  });
  cart.date = new Date().toISOString();
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  const utms = getUrlParams(new URLSearchParams(document.location.search), utmKeys);
  utmKeys.forEach((key) => {
    if (utms[key]) cart[key] = utms[key];
  });
  return cart;
};

export const formatCartUpdatePayload = (sessionCart, updatedProducts, sessionForm) => {
  let cart = {};
  cart.token = sessionCart.token;
  cart.products = [];
  sessionCart.products.forEach((form) => {
    if (form.formId !== sessionForm.formId) {
      cart.products.push({
        form_id: form.formId,
        products: form.items.map((item) =>
          formatProductPayload(item.id, item.quantity, item.custom)
        ),
      });
    }
  });

  updatedProducts.forEach((product) => {
    if (product.quantity === 0) return;

    const formattedProduct = formatProductPayload(
      product.prd_id,
      product.quantity,
      product.prd_custom
    );

    if (product.prd_custom) {
      const index = cart.products.findIndex((form) => form?.form_id === sessionForm.formId);
      if (index !== -1) {
        cart.products[index].products.push(formattedProduct);
      } else {
        cart.products.push({
          form_id: sessionForm.formId,
          products: [formattedProduct],
        });
      }
    } else {
      const index = cart.products.findIndex((form) => !form.form_id);
      if (index !== -1) {
        cart.products[index].products.push(formattedProduct);
      } else {
        cart.products.push({
          form_id: null,
          products: [formattedProduct],
        });
      }
    }
  });
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  const utms = getUrlParams(new URLSearchParams(document.location.search), utmKeys);
  utmKeys.forEach((key) => {
    if (utms[key]) cart[key] = utms[key];
  });
  return cart;
};

export const formatCartContext = (cart, products, sessionForm) => {
  products.forEach((product) => {
    if (product.prd_custom) {
      const index = cart.products.findIndex((form) => form?.formId === sessionForm.formId);
      if (index !== -1) {
        cart.products[index].items.push(product);
      } else {
        cart.products.push({
          formId: sessionForm.formId,
          formName: sessionForm.formName,
          items: [product],
        });
      }
    } else {
      const index = cart.products.findIndex((form) => !form.formId);
      if (index !== -1) {
        cart.products[index].items.push(product);
      } else {
        cart.products.push({
          formId: null,
          formName: null,
          items: [product],
        });
      }
    }
  });
  return cart;
};
