import Quantifier from '../../../components/Quantifier';
import Button from '../../../components/Button';
import { ReactComponent as JfyLogo } from '../../../assets/jfy-logo.svg';
import LoadingDots from '../../../components/LoadingDots';

import styles from './Products.module.css';

const Layout = ({
  shelfProducts,
  loading,
  formulaName,
  loadingNextPage,
  loadingProducts,
  handleClickNext,
  onQuantifierChange,
}) => {
  return (
    <>
      {loading ? (
        <div className={styles.loadingBuildkit}>
          <div className={styles.loadingHeader}>
            <JfyLogo className={styles.loadingLogo} alt="JustForYou Logo" />
          </div>
          <div className={styles.loadingContent}>
            <LoadingDots />
          </div>
        </div>
      ) : (
        <>
          {shelfProducts ? (
            <div className={styles.buildkit}>
              <JfyLogo className={styles.logo} alt="JustForYou Logo" />
              <div className={styles.content}>
                <div className={styles.header}>
                  <h1 className={styles.chooseProductsTitle}>
                    <span className={styles.onlyDesktop}>Para Finalizar, </span>Escolha seus
                    produtos
                  </h1>
                </div>
                {formulaName && (
                  <div className={styles.formulaName}>
                    <h4>Fórmula: {formulaName}</h4>
                  </div>
                )}
                <div className={styles.products}>
                  {shelfProducts.map((product, index) => {
                    return product.prd_shelf ? (
                      <div className={styles.product} key={index}>
                        <div className={styles.wrapper}>
                          <div className={styles.imageWrap}>
                            <img src={product.prd_image} alt={product.prd_dspnam} />
                          </div>
                          <div className={styles.details}>
                            <h3 className={styles.name}>{product.prd_dspnam}</h3>
                            <div className={styles.qtdAndPrice}>
                              <div>
                                <p className={styles.description}>{product.prd_szdisp}</p>
                              </div>
                              <div className={styles.quantifier}>
                                <Quantifier
                                  name={product.prd_id}
                                  min={0}
                                  max={15}
                                  defaultValue={product.default}
                                  onChange={(prd_id, qtd) => onQuantifierChange(prd_id, qtd)}
                                  active={!loadingNextPage}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
                <div className={styles.footer}>
                  <Button
                    primary
                    text="Adicionar ao carrinho"
                    onClick={handleClickNext}
                    className={styles.btnAddToCart}
                    disabled={loadingProducts}
                    loading={loadingNextPage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.buildkit}>
              <JfyLogo className={styles.logo} alt="JustForYou Logo" />
              <div className={styles.content}>
                <div className={styles.loading}>
                  <LoadingDots />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Layout;
