import CheckoutRouter from './routes';
import { FlowProvider } from './contexts/FlowContext';
import { SessionProvider } from './contexts/SessionContext';
import ErrorBoundary from './utils/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import { startTracking } from './analytics/config';

import 'react-toastify/dist/ReactToastify.css';
import styles from './styles/Toast.module.css';
import './styles/normalize.min.css';
import './styles/App.css';

startTracking();

const App = () => (
  <ErrorBoundary>
    <SessionProvider>
      <FlowProvider>
        <CheckoutRouter />
        <ToastContainer
          autoClose={4300}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className={styles.toast}
          bodyClassName={styles.body}
          progressClassName={styles.progressBar}
          position='top-center'
        />
      </FlowProvider>
    </SessionProvider>
  </ErrorBoundary>
);

export default App;
