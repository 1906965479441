import { createContext, useState, useEffect } from 'react';
import { Cart, Form, Product, Shipping, Voucher } from '../models/models';
import { plainToClass } from 'class-transformer';

const SessionContext = createContext({});

const SessionProvider = ({ children }) => {
  const [cart, setCart] = useState(
    plainToClass(Cart, JSON.parse(sessionStorage.getItem('@JFY_CHECKOUT:Cart')) || {})
  );
  const [campaignHash, setCampaignHash] = useState(null);

  const updateCart = (cart) => {
    const cartToken = cart.token || cart.cart_id;
    let totalValue = 0;

    const products = cart.products.map((form) => {
      const formId = form.formId || form.form_id || null;
      const formName = form.formName || form.form_name || null;
      const items = form.items.map((item) => {
        const product = new Product(
          item.id || item.prd_id,
          item.sku || item.prd_sku,
          item.name || item.prd_name,
          item.display_name || item.prd_dspnam || item.displayName,
          item.description || item.prd_dscrp,
          item.value || item.prd_value,
          item.custom || item.prd_custom,
          item.brand || item.prd_brand,
          item.image || item.prd_image,
          item.shelf || item.prd_shelf,
          item.quantity,
          item.benefit || item.prd_benefit,
          item.size || item.prd_szdisp,
          item.weight || item.prd_weight
        );
        totalValue += product.value * product.quantity || 0;
        return product;
      });

      items.sort((a, b) => a.id - b.id);
      return new Form(formId, formName, items);
    });
    products.sort((a, b) => b.items.length - a.items.length);

    let voucher = null;
    if (cart?.voucher) {
      let benefits = [];
      if (cart.voucher.benefits?.length > 0) {
        benefits = [...cart.voucher.benefits];
      } else {
        benefits = cart.voucher.product_id ? [cart.voucher.product_id] : [];
      }
      let discountValue = cart.voucher.discountValue || cart.voucher.discount_value;
      if (!discountValue && cart.voucher.discountValue === 0) discountValue = 0;

      voucher = new Voucher(
        cart.voucher.id,
        cart.voucher.code,
        discountValue,
        cart.voucher.percent || cart.voucher.is_percent,
        cart.voucher.freeShipping || cart.voucher.free_shipment,
        cart.voucher.shipment_value,
        cart.voucher.zip_limitation,
        benefits
      );
    }


    let shipping = null;
    if (cart?.shipping) {
      if (cart.shipping?.zipcode) {
        const zipLimitationValueIsValid = cart?.voucher?.zip_limitation && Number(cart?.voucher?.shipment_value) >= 0 && cart?.voucher?.shipment_value !== null;
        if (zipLimitationValueIsValid) cart.shipping.fee = cart?.voucher?.shipment_value;
        shipping = new Shipping(cart.shipping.zipcode, cart.shipping.fee, cart.shipping.carrier);
      }
    }

    let finalValue = totalValue;
    const shippingFeeIsValidToApply = (!voucher?.freeShipping || (voucher?.zip_limitation && Number(cart?.voucher?.shipment_value) > 0));
    if (shipping && shippingFeeIsValidToApply) finalValue += shipping.fee;
    if (voucher) {
      finalValue -= voucher.percent
        ? (totalValue / 100) * voucher.discountValue
        : voucher.discountValue;
    }

    const newCart = new Cart(cartToken, products, totalValue, voucher, shipping, finalValue);
    setCart(newCart);
    return newCart;
  };

  const updateCampaignHash = (hash) => {
    setCampaignHash(hash);
  };

  useEffect(() => {
    if (campaignHash) {
      sessionStorage.setItem('@JFY_CHECKOUT:Campaign', campaignHash);
    } else {
      sessionStorage.removeItem('@JFY_CHECKOUT:Campaign');
    }
  }, [campaignHash]);

  useEffect(() => {
    sessionStorage.setItem('@JFY_CHECKOUT:Cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <SessionContext.Provider value={{ cart, updateCart, campaignHash, updateCampaignHash }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
