import { toast } from 'react-toastify';
import styles from './notify.module.css';

export const notify = (message, severity = '') => {
  const content = (
    <div className={styles.content}>
      <p>{message}</p>
    </div>
  );
  switch (severity) {
    case 'success':
      toast.success(content);
      break;
    case 'warn':
      toast.warn(content);
      break;
    case 'error':
      toast.error(content);
      break;
    default:
      toast.info(content);
  }
};

export default notify;
export * from './messages';
