import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { sendPurchaseEvent } from '../../analytics/events';
import dayjs from 'dayjs';
import logger from '../../utils/logger';
import { formatPrice } from '../../utils/formatPrice';
import { CheckoutContainer, Block } from '../../components/CheckoutContainer';
import Button from '../../components/Button';
import QrCode from '../../components/QrCode';
import { ReactComponent as Box } from '../../assets/kit-box.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import styles from './AwaitingPayment.module.css';
import checkoutBackend from '../../services/checkoutBackend';
import ReactGA from 'react-ga';

const timeBetweenRequests = [10, 15, 30, 60, 120];

const formatTime = (seconds) => {
  const formatedTime = new Date(0);
  formatedTime.setSeconds(seconds);
  return formatedTime.toISOString().substr(14, 5);
};

export const AwaitingPayment = (args) => {
  const orderId = args.match.params.orderId;
  const [cartInfos, setCartInfos] = useState({});
  const [countdown, setCountdown] = useState();
  const [buttonText, setButtonText] = useState('Copiar código');
  const [renderCopyBtn, setRenderCopyBtn] = useState(true);
  const pixCode = useRef(args.location.state?.pixCode);
  const requestTimeIndex = useRef(0);
  const history = useHistory();

  const checkPaymentStatus = () => {
    logger.trace('Requesting...');

    checkoutBackend('/payment/pix/status/' + orderId, 'GET', {}, true)
      .then((response) => {
        if (response?.toLowerCase() === 'paid') {
          const urlSearch = new URLSearchParams(window.location.search);
          sendPurchaseEvent(
            orderId,
            cartInfos,
            'pix',
            urlSearch.get('hairType'),
            urlSearch.get('kit')
          );
          history.push('/feedback/' + orderId + '/confirmed');
        }

        if (requestTimeIndex.current > 0) {
          requestTimeIndex.current = requestTimeIndex.current - 1;
        }
      })
      .catch((error) => {
        if (requestTimeIndex.current < timeBetweenRequests.length) {
          requestTimeIndex.current = requestTimeIndex.current + 1;
        }
        logger.silentError('Failed to get pix status\n', error, '\n', error.responseJson || null);
      });
  };

  useEffect(() => {
    if (countdown === undefined) return;

    if (countdown > 0) {
      setTimeout(() => {
        if (countdown % timeBetweenRequests[requestTimeIndex.current] === 0) {
          checkPaymentStatus();
        }
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      history.push('/feedback/' + orderId + '/expired');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  useEffect(() => {
    checkoutBackend('/orders/' + orderId, 'GET', {}, true)
      .then((orderResponse) => {
        logger.debug(orderResponse);
        checkoutBackend('/carts/' + orderResponse.cart_id, 'GET', {}, true)
          .then((cartResponse) => {
            pixCode.current = orderResponse.pix_code;
            setCountdown(dayjs(orderResponse.pix_expiration).diff(dayjs(), 'seconds'));
            setCartInfos(cartResponse.result);
          })
          .catch((error) => {
            logger.error('Failed to get cart info\n', error, '\n', error.responseJson || null);
          });
      })
      .catch((error) => {
        logger.error('Failed to get order info\n', error, '\n', error.responseJson || null);
      });
  }, [orderId]);

  useEffect(() => {
    const unblock = history.block((_, action) => action === 'PUSH');

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    // set analytics page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    //try copy to clipboard
    try {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(pixCode.current)
          .then(() => {
            setRenderCopyBtn(true);
          })
          .catch((error) => {
            setRenderCopyBtn(false);
          });
      }
    } catch (e) {
      console.log(e);
      setRenderCopyBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.clipboard]);

  return (
    <CheckoutContainer className={styles.awaitingPayment}>
      <Block title="Aguardando Pagamento" className={styles.block}>
        <div className={styles.content}>
          <p className={styles.firstCopy}>
            Falta pouco para receber seu kit!
            <br />
          </p>
          <p className={styles.countDown}>
            Pague seu Pix dentro de <b>{countdown ? formatTime(countdown) : ''}</b> para garantir
            sua compra!
          </p>
          <div className={styles.boxOrQrCode}>
            <Box className={styles.box} />
            <QrCode content={pixCode.current} className={styles.qrCode} />
            <p className={styles.valueToPay}>
              {cartInfos?.cart ? (
                <>
                  Valor a ser pago no PIX:
                  <b> {formatPrice(cartInfos?.cart?.final_value)}</b>
                </>
              ) : null}
            </p>
          </div>
          <div className={styles.steps}>
            <div>
              <h2>1</h2>
              <p className={styles.mobileOnly}>
                Copie o código abaixo para pagar via Pix em qualquer aplicativo habilitado.
              </p>
              <p className={styles.desktopOnly}>
                Abra seu aplicativo de pagamento onde você utiliza o Pix e escolha a opção{' '}
                <b>Ler QR Code</b>
              </p>
            </div>
            <div>
              <h2>2</h2>
              <p className={styles.mobileOnly}>
                Abra o app do seu banco e escolha a opção Pix Copia e Cola e insira o código copiado
              </p>
              <p className={styles.desktopOnly}>
                Você também pode utilizar a opção Pix Copia e Cola no seu app de pagamento. Caso
                prefira esta opção, copie o código abaixo.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.dropdown}>
          <h2>Código PIX copia e cola</h2>
          <div className={styles.pixCode}>
            <p className="selectableCode">{pixCode.current}</p>
          </div>
        </div>
        <div className={styles.buttons}>
          {renderCopyBtn && (
            <Button
              primary
              className={
                styles.button + (buttonText !== 'Copiar código' ? ` ${styles.copied}` : '')
              }
              text={buttonText}
              icon={<CopyIcon />}
              onClick={() => {
                navigator.clipboard
                  .writeText(pixCode.current)
                  .then(() => {
                    setButtonText('Código copiado!');
                    setTimeout(() => setButtonText('Copiar código'), 3000);
                  })
                  .catch((error) => {
                    logger.silentError('Copy to clipboard failed\n', error);
                  });
              }}
            />
          )}
          <Button
            secondary
            className={styles.button}
            text="Compartilhar código"
            onClick={() =>
              window.open(`https://api.whatsapp.com/send?text=${pixCode.current}`, '_blank').focus()
            }
          />
        </div>
      </Block>
    </CheckoutContainer>
  );
};

export default AwaitingPayment;
