import dataLayerHandler from './dataLayerHandler';
import { formatDataLayerCartItems } from './utils';

export const sendPurchaseEvent = (orderId, cart, paymentType, hairType, kit) => {
  let dataLayerItems = formatDataLayerCartItems(cart, hairType, kit);
  let purchaseInfo = {
    transaction_id: orderId,
    value: cart.absolute_value / 100,
    shipping: cart.cart.cart_shippment_fee / 100,
    coupon: cart?.voucher ? cart?.voucher.code : '',
  };

  dataLayerHandler.purchase(dataLayerItems, purchaseInfo, cart.cart.cart_id);
  dataLayerHandler.purchaseUA(dataLayerItems, purchaseInfo);
  dataLayerHandler.purchaseCustom(paymentType, dataLayerItems, purchaseInfo, cart.cart.cart_id);
};
